import React from 'react';
import { Link } from 'react-router-dom';
import SocialMedia from './socialmedia';
import location from "../imgs/location.svg"

export default function Footer() {
  return (
    <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 bg-blueCTC">
      <div className='flex flex-col'>
        <div className='flex items-start justify-start'>
        <img src={location}
          className='h-4'
        />
        <p className="text-xs text-white">Calle 7 Sur. #3702 Int. 5-A, 72420 | ✉ info@crackin.com.mx</p>
        </div>
        
        <p className="text-xs text-white ml-1">&copy; 2024 CrackIn. Todos los derechos reservados.</p>
      </div>
      
      <nav className="sm:ml-auto flex gap-4 sm:gap-6 justify-center items-center">
        <div className="mt-4 sm:mt-0">
            <SocialMedia />
        </div>
        <Link to="/terms" className="text-xs hover:underline underline-offset-4 text-white">
          Terminos de servicio
        </Link>
      </nav>
    </footer>
  );
}

// Componente que envuelve el contenido colapsable
export function Collapsible({ children, className }) {
  return <div className={`collapsible ${className}`}>{children}</div>;
}

// Componente que actúa como el gatillo para colapsar y desplegar el contenido
export function CollapsibleTrigger({ children, className, onClick }) {
  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
}

// Componente que envuelve el contenido colapsable
export function CollapsibleContent({ children, className, isOpen }) {
  return (
    <div className={`collapsible-content ${isOpen ? "open" : "closed"} ${className}`}>
      {children}
    </div>
  );
}

// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  deleteUser,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore"; // Import Firestore functions

const firebaseConfig = {
  apiKey: "AIzaSyBeM-Y6hz8dzUFSI0960x9p2pH1aCqFvNE",
  authDomain: "sign-in-up-159b8.firebaseapp.com",
  projectId: "sign-in-up-159b8",
  storageBucket: "sign-in-up-159b8.appspot.com",
  messagingSenderId: "150033136649",
  appId: "1:150033136649:web:04575d427cb4d8c4ecccd8",
  measurementId: "G-RER22GMGKZ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const signInWithGoogle = async (redirect) => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    // Extraer el nombre y el apellido del displayName (si existe)
    const displayName = user.displayName || "";
    const [firstName, lastName] = displayName.split(" "); // Divide en nombre y apellido

    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      email: user.email,
      firstName: firstName || "Nombre no disponible", // Manejar si no existe nombre
      lastName: lastName || "Apellido no disponible", // Manejar si no existe apellido
      createdAt: new Date().toISOString(),
    });

    console.log("Usuario registrado:", user);
    redirect();
  } catch (error) {
    console.error("Error al iniciar sesión con Google:", error);
  }
};

const signInWithFacebook = async (redirect) => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    const user = result.user;
    await setDoc(doc(db, "users", user.uid), {
      // Save user info to Firestore
      uid: user.uid,
      email: user.email,
      createdAt: new Date().toISOString(),
    });
    console.log(user);
    redirect();
  } catch (error) {
    console.error(error);
  }
};

const registerWithEmailAndPassword = async (
  email,
  password,
  firstName,
  lastName,
  dayOfBirth,
  monthOfBirth,
  yearOfBirth,
  gender = null,
  referralSource = null
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // Guarda la información del usuario en Firestore
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      email: user.email,
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: {
        day: dayOfBirth,
        month: monthOfBirth,
        year: yearOfBirth,
      },
      gender: gender,
      referralSource: referralSource,
      createdAt: new Date().toISOString(),
    });
    return user;
  } catch (error) {
    throw error;
  }
};

const signInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await firebaseSignInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (!userDoc.exists()) {
      await setDoc(doc(db, "users", user.uid), {
        // Guarda la información del usuario en Firestore si no existe
        uid: user.uid,
        email: user.email,
        createdAt: new Date().toISOString(),
      });
    }
    return user; // Devuelve el usuario si el inicio de sesión es exitoso
  } catch (error) {
    throw error; // Lanza el error para que pueda ser manejado en otro lugar
  }
};

const signOutUser = async () => {
  try {
    await signOut(auth);
    console.log("Cierre de sesión exitoso");
  } catch (error) {
    console.error("Error al cerrar sesión:", error);
  }
};

// Reautenticar al usuario
const reauthenticateUser = async (currentUser, password) => {
  const credential = EmailAuthProvider.credential(currentUser.email, password);
  try {
    await reauthenticateWithCredential(currentUser, credential);
    console.log("Reautenticación exitosa.");
  } catch (error) {
    console.error("Error en la reautenticación:", error);
    throw error;
  }
};

// Eliminar la cuenta del usuario
const deleteAccount = async (currentUser, password = null) => {
  try {
    // Si el usuario ha iniciado sesión con email y contraseña, forzar la reautenticación
    if (password) {
      await reauthenticateUser(currentUser, password);
    }

    // Asegúrate de que el currentUser sea la instancia de auth actual
    const user = auth.currentUser;

    if (!user) {
      throw new Error("No hay un usuario autenticado.");
    }

    // Primero, elimina el documento de Firestore
    await deleteDoc(doc(db, "users", user.uid));
    console.log("Documento de Firestore eliminado.");

    // Luego, elimina la cuenta de Firebase Authentication
    await deleteUser(user);
    console.log("Cuenta de Firebase eliminada.");

    alert("Tu cuenta y los datos asociados han sido eliminados correctamente.");
  } catch (error) {
    console.error("Error al eliminar la cuenta:", error);
    if (error.code === "auth/requires-recent-login") {
      alert(
        "Debes haber iniciado sesión recientemente para eliminar la cuenta. Por favor, vuelve a iniciar sesión e intenta nuevamente."
      );
    } else {
      alert(
        "Ocurrió un error al eliminar la cuenta. Por favor, inténtalo de nuevo."
      );
    }
  }
};

export {
  auth,
  db,
  signInWithGoogle,
  signInWithFacebook,
  registerWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOutUser,
  reauthenticateUser,
  deleteAccount,
};

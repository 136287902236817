import React, { useState, useRef } from 'react';
import { AMDF } from 'pitchfinder';

const AudioAnalyzer = () => {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [detectedNote, setDetectedNote] = useState(null);
  const [frozenNotes, setFrozenNotes] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const audioContextRef = useRef(null);
  const processorRef = useRef(null);

  const correctCombination = ['DO', 'LA', 'LA', 'SI'];

  const noteFromFrequency = (frequency) => {
    const notes = ['DO#', 'RE', 'RE#', 'MI', 'FA', 'FA#', 'SOL', 'SOL#', 'LA', 'LA#', 'SI', 'DO'];
    const A4 = 440;
    const semitone = 69;
    const n = 12 * Math.log2(frequency / A4);
    const noteIndex = Math.round(n + semitone) % 12;
    return notes[noteIndex];
  };

  const startAnalysis = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);

        const detectPitch = AMDF();

        processorRef.current.onaudioprocess = (event) => {
          const inputData = event.inputBuffer.getChannelData(0);
          const pitch = detectPitch(inputData);
          
          if (pitch !== null) {
            const note = noteFromFrequency(pitch);
            setDetectedNote(note);
            console.log('Nota detectada:', note);
          }
        };

        source.connect(processorRef.current);
        processorRef.current.connect(audioContextRef.current.destination);
        setIsAnalyzing(true);
      })
      .catch(error => {
        console.error('Error al acceder al micrófono:', error);
      });
  };

  const stopAnalysis = () => {
    if (processorRef.current) {
      processorRef.current.disconnect();
      processorRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    setIsAnalyzing(false);
    setDetectedNote(null);
  };

  const freezeNote = () => {
    if (frozenNotes.length < 4 && detectedNote) {
      const newFrozenNotes = [...frozenNotes, detectedNote];
      setFrozenNotes(newFrozenNotes);

      if (newFrozenNotes.length === 4) {
        if (newFrozenNotes.join(' ') === correctCombination.join(' ')) {
          setSuccessMessage('¡Bien hecho! Has acertado la combinación de notas.');
        } else {
          setSuccessMessage('Combinación incorrecta. Inténtalo de nuevo.');
        }
      }
    }
  };

  const resetNotes = () => {
    setFrozenNotes([]);
    setSuccessMessage('');
  };

  return (
    <div className='fixed inset-0 flex justify-center items-center bg-black z-50'>
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">Análisis de Audio</h2>
        
        <div className="flex flex-col items-center space-y-4">
          <button
            onClick={startAnalysis}
            disabled={isAnalyzing}
            className={`bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50 ${isAnalyzing ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isAnalyzing ? 'Analizando...' : 'Iniciar Análisis'}
          </button>

          <button
            onClick={stopAnalysis}
            disabled={!isAnalyzing}
            className={`bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50 ${!isAnalyzing ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            Detener Análisis
          </button>
          
          {detectedNote && (
            <div className="mt-4 bg-white p-4 rounded-md shadow-inner">
              <h2 className="text-xl font-bold">Nota detectada:</h2>
              <p className='text-4xl font-semibold'>{detectedNote}</p> 
              <button
                onClick={freezeNote}
                className="mt-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-700 focus:outline-none"
                disabled={frozenNotes.length >= 4}
              >
                Congelar Nota
              </button>
            </div>
          )}

          {frozenNotes.length > 0 && (
            <div className="mt-4 bg-white p-4 rounded-md shadow-inner w-full">
              <h2 className="text-xl font-bold">Notas congeladas:</h2>
              <div className="flex justify-around">
                {frozenNotes.map((note, index) => (
                  <p key={index} className='text-2xl font-semibold'>{note}</p>
                ))}
              </div>
            </div>
          )}

          {successMessage && (
            <div className="mt-4 bg-yellow-500 p-4 rounded-md shadow-inner">
              <p className="text-lg font-bold">{successMessage}</p>
            </div>
          )}

          {frozenNotes.length === 4 && (
            <button
              onClick={resetNotes}
              className="mt-4 bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 focus:outline-none"
            >
              Reiniciar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioAnalyzer;

import React, { useState } from "react";
import Header from "./header";
import { ChevronRightIcon } from "./Icons";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "./ui/collapsible";
import Footer from "./footer";
import { termsAndConditions } from '../data'; // Importamos los términos y condiciones desde el archivo data.js
import Fondocalle1 from "../imgs/Fondo-1-calle.png"

export default function Terms() {
  const [openCollapsible, setOpenCollapsible] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleCollapsible = (index) => {
    setOpenCollapsible(openCollapsible === index ? null : index);
  };

  return (
    <div className={`flex flex-col w-full min-h-[100dvh] ${menuOpen ? 'mt-[40vh]' : 'mt-0'} transition-all duration-300`}>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} /> {/* Pasar el estado del menú al Header */}
      <section className="w-full min-h-[250vh] py-12 bg-blueCTC/95" style={{ backgroundImage: `url(${Fondocalle1})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="container max-w-full px-4 md:px-6">
            <div className="mx-auto max-w-4xl space-y-6">
                <div className="space-y-2 flex flex-col items-center justify-center">
                  <div className="md:w-[45vw] w-auto flex items-center justify-center">
                    <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-8 text-white bg-redCTC text-center font-nebulica">Términos y Condiciones</h2>
                  </div>
                  <p className="text-muted-foreground md:text-lg text-white font-roboto-mono">
                  Los presentes Términos y Condiciones son vinculantes para todas aquellas personas visitantes
                    o usuarios de la página web www.crackin.com.mx (en adelante: El Sitio) y/o de la aplicación 
                    CrackIn (en adelante: La Aplicación), por lo tanto, cualquier interacción con las mismas se 
                    entiende como una aceptación manifiesta de todo lo aquí expuesto. En este entendido, cualquier 
                    persona que no estuviese de acuerdo con el contenido del presente documento deberá desistirse 
                    de usar La Aplicación y El Sitio, sin poder acceder a los servicios ofrecidos ninguna de éstas.
                  </p>
                </div>
                <Collapsible className="space-y-4">
                  {termsAndConditions.map((term, index) => (
                    <div key={index}>
                      <CollapsibleTrigger
                        className="flex w-full items-center justify-between rounded-md bg-muted px-4 py-3 text-lg font-semibold transition-colors hover:bg-muted/80 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                        onClick={() => toggleCollapsible(index)}
                      >
                        <span className="text-white font-roboto-mono">{term.title}</span>
                        <ChevronRightIcon className={`text-redCTC h-5 w-5 transition-transform duration-300 ${openCollapsible === index ? "rotate-90" : ""}`} />
                      </CollapsibleTrigger>
                      <CollapsibleContent className={`px-4 pt-3 text-sm text-muted-foreground transition-max-height duration-500 overflow-hidden ${openCollapsible === index ? "max-h-screen" : "max-h-0"}`}>
                        <p className="text-white font-roboto-mono" dangerouslySetInnerHTML={{ __html: term.content }} />
                      </CollapsibleContent>
                    </div>
                  ))}
                </Collapsible>
            </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

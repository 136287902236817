import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import BigBroSwitch from "../imgs/BigBroSwitch.jpg"; // Importamos la imagen
import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";
import timeRedIcon from "../imgs/timer-red.svg"; // Importamos el icono del temporizador

//Importa el timer
import { TimerContext } from "./timerContext_new";

//Firebase
import { useAuth } from "./AuthProvider";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const BigBrother = () => {
  const [showPopup, setShowPopup] = useState(false); // Controla si el PopUp está abierto
  const [error, setError] = useState(""); // Controla los errores
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false); // Controla si la contraseña es correcta
  const navigate = useNavigate();

  const { timeLeft } = useContext(TimerContext);
  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // Maneja la validación de la contraseña y actualización de Firestore
  const handlePasswordSubmit = async (password) => {
    if (password === "1984") {
      console.log("Contraseña correcta. Procediendo a actualizar la fase...");

      try {
        // Obtiene el documento del usuario para verificar el currentPhase actual
        const userDocRef = doc(db, "big_brother", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const initialPhase = userDocSnap.data().currentPhase || 0;
          console.log("Fase actual antes de actualizar:", initialPhase);

          // Actualiza currentPhase a 4 en Firestore
          await updateDoc(userDocRef, { currentPhase: 4 });
          console.log("Fase actualizada a 4 en Firestore.");

          // Verifica si la fase se actualizó correctamente
          const updatedDocSnap = await getDoc(userDocRef);
          const updatedPhase = updatedDocSnap.data().currentPhase || 0;
          console.log("Fase actual después de actualizar:", updatedPhase);

          if (updatedPhase === 4) {
            // Solo navega si la fase fue actualizada correctamente
            console.log("Navegando a /PuntoPuntoPunto en 3 segundos...");
            setTimeout(() => {
              setShowPopup(false);
              navigate("/PuntoPuntoPunto");
            }, 3000);
          } else {
            console.error(
              "Error: La fase no se actualizó correctamente en Firestore."
            );
            setError(
              "Hubo un problema al actualizar la fase. Intenta nuevamente."
            );
          }
        } else {
          console.error(
            "Error: Documento del usuario no encontrado en Firestore."
          );
          setError("Hubo un problema al obtener los datos del usuario.");
        }
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      console.log("Contraseña incorrecta:", password);
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black">
      <div className="text-black text-4xl mb-8">
        {/* Muestra el temporizador en la pantalla */}
        <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
        <ol>
          <li className="mb-4">
            1) 52° 28' 46.506", 62° 11' 7.0002.
            <ul className="text-lg list-disc pl-4 mt-2">
              <li>
                Esta figura geométrica invertida es la favorita del diablo.
              </li>
            </ul>
          </li>

          <li className="mb-4">
            2) Wikipedia de la figura invertida: 4ta referencia
            <ul className="text-lg list-disc pl-4 mt-2">
              <li>4 de febrero de 2013. Consultado el 20 de julio de 2014.</li>
            </ul>
          </li>

          <li className="mb-4">
            3) Busca el ojo en el Twitter de este personaje.
            <ul className="text-lg list-disc pl-4 mt-2">
              <li>Asegúrense de tener el nombre completo (A.S.L.)</li>
            </ul>
          </li>
        </ol>
      </div>
      <img src={BigBroSwitch} alt="Switch" className="h-64" />{" "}
      {/* Imagen del switch */}
      <ContinueButton setShowPopup={setShowPopup} />
      {/* Renderizar el PopUp solo si está abierto */}
      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit} // Pasamos la función para verificar la contraseña
          onClose={() => setShowPopup(false)} // Cierra el PopUp si es necesario
          error={error} // Pasamos el error si hay uno
        />
      )}
      {/* Mostrar contenido adicional solo si la contraseña es correcta */}
      {isPasswordCorrect && (
        <p className="text-white text-3xl mt-8">
          ¡Contraseña correcta! Puedes continuar con el juego.
        </p>
      )}
    </div>
  );
};

export default BigBrother;

import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../AuthProvider";

const PaymentPopup = ({ onClose, navigate, collectionName, phaseNumber }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const { currentUser } = useAuth();

  // Función para actualizar el progreso en Firestore
  const updateProgressInFirestore = async (userUID) => {
    const userDocRef = doc(db, collectionName, userUID);

    try {
      await setDoc(
        userDocRef,
        {
          uid: userUID,
          hasPaid: true,
          currentPhase: phaseNumber,
        },
        { merge: true }
      );
      console.log(`Progreso en ${collectionName} actualizado.`);
    } catch (error) {
      console.error("Error al actualizar el progreso en Firestore:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const { data } = await axios.post(
        "https://ctc-web-qa7a.onrender.com/api/create-payment-intent",
        {
          amount: 24900,
        }
      );

      const clientSecret = data.clientSecret;

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (error) {
        console.error("Error en el pago:", error);
        setError(error.message);
        setIsProcessing(false);
      } else if (paymentIntent.status === "succeeded") {
        await updateProgressInFirestore(currentUser.uid);

        setIsProcessing(false);
        setPaymentSuccess(true);
        setTimeout(() => {
          onClose();
        }, 3000);
      }
    } catch (err) {
      console.error("Error al procesar el pago:", err);

      setError("Error al procesar el pago, por favor inténtalo nuevamente.");
      setIsProcessing(false);
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "white",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          {paymentSuccess ? "Pago Exitoso!" : "Pago $249"}
        </h2>

        {!paymentSuccess ? (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <CardElement options={cardStyle} />
            </div>
            <button
              className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center justify-center"
              type="submit"
              disabled={!stripe || isProcessing}
            >
              {isProcessing ? "Procesando..." : "Pagar"}
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </form>
        ) : (
          <p className="text-green-500 text-lg">
            ¡El pago fue procesado con éxito!
          </p>
        )}
        <p className="text-gray-400 text-sm mt-4 text-end">Powered by Stripe</p>
      </div>
    </div>
  );
};

export default PaymentPopup;

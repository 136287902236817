import React, { useState } from "react";
import {
  signInWithGoogle,
  signInWithEmailAndPassword,
  signInWithFacebook,
} from "../../firebase";
import { Link, useNavigate } from "react-router-dom";

export default function Form() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(email, password);
      alert("Inicio de sesión exitoso");
      navigate("/");
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      if (error.code === "auth/user-not-found") {
        alert("Usuario no existente. Por favor, regístrate.");
      } else if (error.code === "auth/wrong-password") {
        alert("Contraseña incorrecta. Por favor, intenta de nuevo.");
      } else {
        alert(
          "Hubo un error al iniciar sesión. Por favor, inténtalo de nuevo."
        );
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle(() => navigate("/"));
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error);
      alert(
        "Hubo un error al iniciar sesión con Google. Por favor, inténtalo de nuevo."
      );
    }
  };

  //   const handleFacebookLogin = async () => {
  //     try {
  //       await signInWithFacebook(() => navigate("/"));
  //     } catch (error) {
  //       console.error("Error al iniciar sesión con Facebook:", error);
  //       alert(
  //         "Hubo un error al iniciar sesión con Facebook. Por favor, inténtalo de nuevo."
  //       );
  //     }
  //   };

  return (
    <div className="bg-blueCTC px-10 py-10 rounded-3xl border-2 border-gray-100">
      <h1 className="text-5xl font-semibold text-redCTC">
        Bienvenido de nuevo
      </h1>
      <p className="font-medium text-lg text-gray-500 mt-4">
        Bienvenido de nuevo! Por favor ingresa tus datos.
      </p>
      <div className="mt-8">
        <div>
          <label className="text-lg font-semibold text-redCTC">
            Correo electrónico
          </label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
            placeholder="Ingresa tu correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label className="text-lg font-semibold text-redCTC">
            Contraseña
          </label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
            placeholder="Ingresa tu contraseña"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mt-8 flex justify-between items-center">
          <button className="font-semibold text-base text-redCTC">
            Forgot password?
          </button>
        </div>
        <div className="mt-8 flex flex-col gap-y-4">
          <button
            onClick={handleLogin}
            className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 bg-redCTC text-white text-lg font-bold rounded-xl"
          >
            Iniciar sesión
          </button>
          <button
            onClick={handleGoogleLogin}
            className="flex border-2 border-gray-100 items-center justify-center gap-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out font-semibold rounded-xl py-3 bg-white"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.26644 9.76453C6.19903 6.93863 8.85469 4.90909 12.0002 4.90909C13.6912 4.90909 15.2184 5.50909 16.4184 6.49091L19.9093 3C17.7821 1.14545 15.0548 0 12.0002 0C7.27031 0 3.19799 2.6983 1.24023 6.65002L5.26644 9.76453Z"
                fill="#EA4335"
              />
              <path
                d="M16.0406 18.0142C14.9508 18.718 13.5659 19.0926 11.9998 19.0926C8.86633 19.0926 6.21896 17.0785 5.27682 14.2695L1.2373 17.3366C3.19263 21.2953 7.26484 24.0017 11.9998 24.0017C14.9327 24.0017 17.7352 22.959 19.834 21.0012L16.0406 18.0142Z"
                fill="#34A853"
              />
              <path
                d="M19.8342 20.9978C22.0292 18.9503 23.4545 15.9019 23.4545 11.9982C23.4545 11.2891 23.3455 10.5255 23.1818 9.81641H12V14.4528H18.4364C18.1188 16.0119 17.2663 17.2194 16.0407 18.0108L19.8342 20.9978Z"
                fill="#4A90E2"
              />
              <path
                d="M5.27698 14.2663C5.03833 13.5547 4.90909 12.7922 4.90909 11.9984C4.90909 11.2167 5.03444 10.4652 5.2662 9.76294L1.23999 6.64844C0.436587 8.25884 0 10.0738 0 11.9984C0 13.918 0.444781 15.7286 1.23746 17.3334L5.27698 14.2663Z"
                fill="#FBBC05"
              />
            </svg>
            Ingresar con Google
          </button>
          {/* <button 
                        onClick={handleFacebookLogin}
                        className='flex border-2 border-gray-100 bg-blue-500 items-center justify-center gap-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out font-semibold rounded-xl py-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28" height="28" viewBox="0 0 48 48">
                            <path fill="#3F51B5" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path>
                            <path fill="#FFF" d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"></path>
                        </svg>
                        Ingresar con Facebook
                    </button> */}
          {/* Botón para iniciar sesión con apple */}
          {/* 
                    <button 
                        //onClick={signInWithApple}
                        className='flex border-2 border-gray-100 items-center justify-center gap-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out font-semibold rounded-xl py-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 30 30">
                            <path d="M25.565,9.785c-0.123,0.077-3.051,1.702-3.051,5.305c0.138,4.109,3.695,5.55,3.756,5.55 c-0.061,0.077-0.537,1.963-1.947,3.94C23.204,26.283,21.962,28,20.076,28c-1.794,0-2.438-1.135-4.508-1.135 c-2.223,0-2.852,1.135-4.554,1.135c-1.886,0-3.22-1.809-4.4-3.496c-1.533-2.208-2.836-5.673-2.882-9 c-0.031-1.763,0.307-3.496,1.165-4.968c1.211-2.055,3.373-3.45,5.734-3.496c1.809-0.061,3.419,1.242,4.523,1.242 c1.058,0,3.036-1.242,5.274-1.242C21.394,7.041,23.97,7.332,25.565,9.785z M15.001,6.688c-0.322-1.61,0.567-3.22,1.395-4.247 c1.058-1.242,2.729-2.085,4.17-2.085c0.092,1.61-0.491,3.189-1.533,4.339C18.098,5.937,16.488,6.872,15.001,6.688z"></path>
                        </svg>
                        Ingresar con Apple
                    </button>
                    */}
        </div>
        <div className="mt-8 flex justify-center items-center">
          <p className="font-semibold text-base text-redCTC">
            ¿No tienes una cuenta?
          </p>
          <Link
            to="/signup"
            className="text-white text-base font-semibold ml-2"
          >
            Registrate
          </Link>
        </div>
      </div>
    </div>
  );
}

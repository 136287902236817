import React from "react";
import continueIcon from "../../imgs/continue_white.svg";

const ContinueButton = ({ setShowPopup }) => {
  return (
    <div className="mt-6 flex items-center justify-center">
      <button
        className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center justify-center"
        onClick={() => setShowPopup(true)}
      >
        <img src={continueIcon} alt="Icono de continuar" className="w-6 h-6" />
        Continuar
      </button>
    </div>
  );
};

export default ContinueButton;

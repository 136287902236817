import React from "react";
import { useNavigate } from "react-router-dom";

const LoginRequiredPopup = ({ onClose }) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    onClose();
    navigate("/signin");
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-80 sm:w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow text-center">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-xl text-white font-nebulica font-bold mb-2">
          Para jugar debes iniciar sesión
        </h2>
        <p className="text-sm text-gray-200 font-roboto-mono mb-6">
          Inicia sesión para acceder a esta actividad.
        </p>
        <button
          onClick={handleLogin}
          className="bg-gradient-to-b from-redCTC to-redCTCbuttons text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center justify-center mx-auto"
        >
          Crack-In
        </button>
      </div>
    </div>
  );
};

export default LoginRequiredPopup;

import React, { useState, useEffect, useRef } from "react";
import ReactCardFlip from "react-card-flip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cardData } from "../data";
import { useNavigate } from "react-router-dom";
import TermsPopup from "./termsPopUp"; // Importar el pop-up
import candado from "../imgs/candado-white.svg";
import "../index.css";

const FlipCards = ({ highlightedCity, highlightedTerminal }) => {
  const [isMobile, setIsMobile] = useState(false);
  const sliderRef = useRef(null);
  const [showTerms, setShowTerms] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const navigate = useNavigate();
  const [highlightedIndex, setHighlightedIndex] = useState(null);

  // Detectar si estamos en un dispositivo móvil
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define el tamaño de corte para móvil
    };

    handleResize(); // Llamar cuando el componente se monte
    window.addEventListener("resize", handleResize); // Escuchar los cambios de tamaño

    return () => {
      window.removeEventListener("resize", handleResize); // Limpia el evento al desmontar
    };
  }, []);

  // Buscar el índice de la tarjeta correspondiente a la ciudad y terminal seleccionada
  useEffect(() => {
    const foundIndex = cardData.findIndex(
      (card) =>
        card.ciudad === highlightedCity && card.terminal === highlightedTerminal
    );
    setHighlightedIndex(foundIndex);

    // Desplazar el carrusel a la tarjeta correspondiente
    if (sliderRef.current && foundIndex !== -1) {
      sliderRef.current.slickGoTo(foundIndex);
    }
  }, [highlightedCity, highlightedTerminal]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    ref: sliderRef,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [flippedStates, setFlippedStates] = useState(
    Array(cardData.length).fill(false)
  );

  const flipCard = (index) => {
    const newFlippedStates = [...flippedStates];
    newFlippedStates[index] = !newFlippedStates[index];
    setFlippedStates(newFlippedStates);
  };

  // Mostrar el pop-up de términos y condiciones
  const handleCrackIn = (room) => {
    setSelectedRoom(room);
    setShowTerms(true);
  };

  // Aceptar términos y redirigir según el cuarto seleccionado
  const acceptTerms = () => {
    setShowTerms(false);
    // Redirigir según el cuarto seleccionado
    if (selectedRoom.id === "h1") {
      navigate("/IntroClub42");
    } else if (selectedRoom.id === "h2") {
      navigate("/paciente0");
    } else if (selectedRoom.id === "h3") {
      navigate("/puerta33");
    }
  };

  return (
    <div className="w-3/4 m-auto -mt-28">
      <Slider {...settings}>
        {cardData.map((card, index) => (
          <div key={index} className={`card-container`}>
            <ReactCardFlip
              flipDirection="horizontal"
              isFlipped={flippedStates[index]}
              flipSpeedBackToFront={0.8}
              flipSpeedFrontToBack={0.8}
            >
              <div
                className={`card ${
                  highlightedIndex === index ? "border-highlight" : ""
                }`}
                onMouseEnter={!isMobile ? () => flipCard(index) : null}
                onClick={isMobile ? () => flipCard(index) : null}
              >
                <img src={card.imagen} alt={card.nombre} />
                <div className="card-bottom flex-col bg-gradient-to-b from-redCTC from-10% to-redCTCbuttons to-60%">
                  <div className="half red">
                    <p className="font-nebulica text-[1.6875rem]">
                      {card.nombre}
                    </p>
                  </div>
                  <div className="half blue">
                    <p className="text-white text-xs md:text-sm">
                      {card.dificultad}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="card card-back"
                onMouseLeave={!isMobile ? () => flipCard(index) : null}
                onClick={isMobile ? () => flipCard(index) : null}
              >
                <div className="flex bg-blueCTC items-center justify-center py-4 md:py-8">
                  <h2 className="text-white font-nebulica text-2xl md:text-4xl">
                    {card.nombre}
                  </h2>
                </div>
                <div className="px-4 py-4 font-roboto-mono text-sm md:text-md">
                  <p className="text-white text-sm md:text-base">
                    <strong className="font-nebulica">
                      Sinopsis: <br />
                    </strong>{" "}
                    {card.sinopsis}
                  </p>
                  <p className="text-white mt-2 md:mt-3.5 text-sm md:text-base">
                    <strong className="font-nebulica">Lugar: </strong>{" "}
                    {card.lugar}
                  </p>
                  <p className="text-white mt-2 md:mt-3.5 text-sm md:text-base">
                    <strong className="font-nebulica">
                      Distancia a recorrer:{" "}
                    </strong>{" "}
                    {card.distancia}
                  </p>
                  <p className="text-white mt-2 md:mt-3.5 text-sm md:text-base">
                    <strong className="font-nebulica">Tiempo: </strong>{" "}
                    {card.tiempo}
                  </p>
                </div>
                {/* Botón Crack-In */}
                <div className="flex items-center justify-center sm:mt-8">
                  <button
                    className="inline-flex h-8 md:h-10 items-center justify-center rounded-md bg-blueCTC text-white text-sm md:text-lg font-roboto-mono font-semibold px-4 md:px-6 gap-2"
                    onClick={() => handleCrackIn(card)} // Mostrar el pop-up al hacer clic
                  >
                    <img src={candado} alt="Candado" className="h-6 w-6" />
                    Crack-In
                  </button>
                </div>
              </div>
            </ReactCardFlip>
          </div>
        ))}
      </Slider>

      {/* Pop-up de Términos y Condiciones */}
      {showTerms && (
        <TermsPopup
          onAccept={acceptTerms}
          onClose={() => setShowTerms(false)}
        />
      )}
    </div>
  );
};

export default FlipCards;

import React, { useState, useEffect, useContext } from "react";
import PasswordPopup from "./popUps/passwordPopup";
import SuccessPopup from "./popUps/successPopup";
import { TimerContext } from "./timerContext_new"; // Importa el contexto del temporizador

import { useAuth } from "./AuthProvider";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase"; //Configuración de Firebase

import fondo3 from "../imgs/cuartos/asesinoimitador/fondo3.webp";
import certificado from "../imgs/cuartos/asesinoimitador/certificado.webp";
import continueIcon from "../imgs/continue_white.svg";
import timeRedIcon from "../imgs/timer-red.svg";

const AsesinoImitador4 = () => {
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState("");
  const { timeLeft, stopTimer } = useContext(TimerContext);
  const { currentUser } = useAuth();

  // Función para formatear el tiempo
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // Calcula el tiempo que se ha tardado
  const calculateTimeElapsed = () => {
    const totalTime = 60 * 60; // 3600 segundos (60 minutos)
    const timeElapsedInSeconds = totalTime - timeLeft;
    return formatTime(timeElapsedInSeconds); // Devuelve el tiempo transcurrido formateado
  };

  // Lógica para verificar la clave
  const verifyClave = (clave) => {
    if (clave === "Mark Sanders Baker") {
      stopTimer(); // Detiene el temporizador cuando se completa el desafío
      setShowPasswordPopup(false); // Cierra el popup de la clave
      setShowSuccessPopup(true); // Muestra el popup de felicitaciones
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  //CurrentPhase
  useEffect(() => {
    const setInitialPhase = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "asesino_imitador", currentUser.uid);
        await setDoc(userDocRef, { currentPhase: 4 }, { merge: true });
      }
    };
    setInitialPhase();
  }, [currentUser]);

  return (
    <div
      className="h-auto min-h-[100vh] flex"
      style={{
        backgroundImage: `url(${fondo3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-screen m-auto my-6 p-6 h-auto bg-blueCTC/20 rounded-lg shadow-lg text-white mx-8">
        {/* Muestra el temporizador en la pantalla */}
        <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>

        <div className="bg-yellow-100 text-black p-4 font-roboto-mono">
          <p className="mb-4">
            Dentro de la caja fuerte descubren el{" "}
            <span className="text-red-500">acta de nacimiento</span> del
            asesino. Han realizado un trabajo excepcional, sin embargo, la
            misión aún no ha concluido.
          </p>
          <p className="mb-4">
            Para atraparlo, es crucial que conozcamos{" "}
            <span className="font-bold">su nombre completo</span>.
          </p>
          <p className="mb-4">
            Envíen de inmediato el nombre al jefe de comisaría Jones.
          </p>
          <p>
            No hay tiempo que perder, actúen rápido antes de que el asesino se
            fugue definitivamente.
          </p>
        </div>
        <div className="flex justify-center my-4">
          <img
            className="w-auto h-64 lg:h-[60vh]"
            src={certificado}
            alt="Certificado de nacimiento"
          />
        </div>
        <div className="mt-6 flex justify-center">
          <button
            className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center justify-center"
            onClick={() => setShowPasswordPopup(true)}
          >
            <img
              src={continueIcon}
              alt="Icono de continuar"
              className="w-6 h-6"
            />
            Enviar Nombre
          </button>
        </div>

        {/* Pop-up para ingresar el nombre del asesino */}
        {showPasswordPopup && (
          <PasswordPopup
            onSubmit={verifyClave} // Verifica la clave ingresada
            onClose={() => setShowPasswordPopup(false)} // Cierra el popup de la clave
            titleText="Ingresa el Nombre del Asesino Imitador" // Personaliza el título
            placeholderText="Nombre completo" // Personaliza el placeholder
            buttonText="Verificar nombre"
            error={error}
          />
        )}

        {/* Pop-up de felicitaciones si la clave es correcta */}
        {showSuccessPopup && (
          <SuccessPopup
            onClose={() => setShowSuccessPopup(false)}
            timeElapsed={calculateTimeElapsed()} // Pasa el tiempo transcurrido al popup de éxito
            message={"¡Felicidades! Han atrapado al asesino imitador."}
            roomId="asesino_imitador" // Cambia esto por el ID del cuarto actual
          />
        )}
      </div>
    </div>
  );
};

export default AsesinoImitador4;

import React from "react"
import Colaboracion from '../imgs/personalizados/colaboracionempleados.svg'
import Compañerismo from '../imgs/personalizados/compañerismo.svg'
import Especiales from '../imgs/personalizados/escaperoomsespeciales.svg'
import SoftSkills from '../imgs/personalizados/softskills.svg'
import Lanzamiento from '../imgs/personalizados/lanzamientodeproductos.svg'

export default function Personalizados() {
    return (
        <div className="text-foreground">
          <div className="flex flex-col items-center justify-center">
            <p className="w-3/4 text-sm md:text-lg text-white font-roboto-mono text-center">
              Con orgullo, hemos colaborado con marcas de renombre mundial como <span className="text-redCTC">Hasbro, Pepsico, PlayStation, Sony y ViacomCBS</span>, creando experiencias de escape room que son verdaderamente únicas.
            </p>
            <p className="w-3/4 mt-6 text-sm md:text-lg text-white font-roboto-mono text-center">
              Para empresas como <span className="text-redCTC">Bizzarro, Ideas Activas y La Haus</span>, diseñamos cuartos de escape personalizados que fortalecen el trabajo en equipo y celebran hitos corporativos. 
            </p>
            <p className="w-3/4 mt-6 text-sm md:text-lg text-white font-roboto-mono text-center">
              Además, hemos creado dinámicas educativas para instituciones como <span className="text-redCTC">Hatzala y Cadena</span>, y decenas de colegios, así como experiencias interactivas para comunidades en la Ciudad de México. 
            </p>
          </div>
          <div className="mt-12 grid grid-cols-1 lg:grid-cols-3 gap-4 md:px-32">
            <div className="flex flex-col items-center justify-center">
              <img src={Colaboracion} alt="Colaboración" />
              <p className="text-white text-center mt-12 font-roboto-mono px-6">
               Cuartos de escape diseñados para incentivar la <span className="text-redCTC">comunicación</span> y fomentar el <span className="text-redCTC">trabajo en equipo</span> hacia un objetivo en común.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <img src={Compañerismo} alt="Compañerismo" />
              <p className="text-white text-center mt-12 font-roboto-mono px-6">
                Experiencias interactivas que promueven la <span className="text-redCTC">integración</span> y el <span className="text-redCTC">compañerismo</span>.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <img src={Especiales} alt="Especiales" />
              <p className="text-white text-center mt-12 font-roboto-mono px-6">
                Escape rooms personalizados para celebrar <span className="text-redCTC">fechas importantes</span> y aniversarios.
              </p>
            </div>
          </div>
          <div className="mt-12 grid grid-cols-1 lg:grid-cols-2 px-4 md:px-32">
            <div className="flex flex-col items-center justify-center">
              <img src={SoftSkills} alt="Colaboración" />
              <p className="text-white text-center mt-12 font-roboto-mono px-6 lg:px-12">
                Cuartos de escape que combinan entretenimiento con <span className="text-redCTC">capacitaciones</span> corporativas y desarrollo de <span className="text-redCTC">habilidades blandas</span>.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center mt-8 md:mt-0">
              <img src={Lanzamiento} alt="Compañerismo" />
              <p className="text-white text-center mt-12 font-roboto-mono px-6 lg:px-12">
                Experiencias especiales para <span className="text-redCTC">lanzamientos</span> de productos y <span className="text-redCTC">eventos</span> corporativos.  
              </p>
            </div>
          </div>
        </div>
    )
}

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fondoClub42 from "../imgs/cuartos/club42/fondo_club42.webp";
import logoClub42 from "../imgs/cuartos/club42/logo.png";
import signature from "../imgs/cuartos/club42/signature.png";
import ContinueButtonGold from "./buttons/continueButtonGold";
import PasswordPopup from "./popUps/passwordPopup";
import { TimerContext } from "./timerContext_new"; // Importa el contexto del temporizador
import { useAuth } from "./AuthProvider"; // Importa el contexto de autenticación
import timeRedIcon from "../imgs/timer-red.svg";

const IntroClub42 = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { timeLeft, startTimer } = useContext(TimerContext); // Accede al temporizador y la función para iniciarlo
  const { currentUser } = useAuth(); // Accede al usuario actual desde el AuthContext

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePasswordSubmit = (password) => {
    if (password === "STEV") {
      // Verifica la contraseña
      setShowPopup(false);
      setError(""); // Limpia el error
      navigate("/sala19"); // Redirige a la siguiente página
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  useEffect(() => {
    // Solo iniciar el temporizador si no ha sido iniciado antes
    const timerStarted = localStorage.getItem("timerStartTime");
    if (!timerStarted) {
      startTimer(); // Inicia el temporizador solo si no está corriendo
    }
  }, [startTimer]);

  // Si no hay usuario, usa un valor por defecto
  const lastName = currentUser?.lastName || "Apellido no disponible";

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center text-white"
      style={{
        backgroundImage: `url(${fondoClub42})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Logo */}
      <div className="my-12">
        <img src={logoClub42} alt="Club 42 Logo" className="w-48 h-auto" />
      </div>

      {/* Texto principal */}
      <div className="p-8 max-w-5xl text-left">
        <p className="mb-4 text-lg">
          <strong className="">Sr {lastName}</strong>, <br /> Si está leyendo
          estas palabras, es porque ha sido seleccionado para formar parte de un
          grupo significativamente exclusivo, al cual únicamente la élite del
          mundo tiene acceso.
        </p>
        <p className="mb-4 text-lg">
          Hace meses, en las sombras, inauguramos una red de salones privados en
          los aeropuertos más importantes de México. Estos refugios, camuflados
          en distintas terminales, son el santuario de aquellos que mueven los
          hilos del mundo.
        </p>
        <p className="mb-4 text-lg">
          Para acceder a este enclave de poder, se requiere algo más que una
          simple invitación. Debe demostrar su valía, ingenio y determinación.
          Solo aquellos capaces de superar una serie de pruebas cuidadosamente
          diseñadas serán dignos de ingresar a este selecto círculo.
        </p>
        <p className="mb-4 text-lg">
          Si está dispuesto a aceptar el desafío, lo invito a dirigirse a la
          sala 19. Allí, aguarda su destino, su oportunidad de probar que merece
          un lugar entre nosotros.
        </p>
        <p className="mb-4 text-lg">
          Recuerde, solo los más astutos y osados lograrán encontrar el camino
          hacia nosotros. ¿Está listo para demostrar que eres uno de ellos?
        </p>
        {/* Firma */}
        <div className="flex justify-start mb-4">
          <img src={signature} alt="Firma" className="w-32 h-auto" />
        </div>
        <p className="text-sm">
          <strong>Con altivez y expectación, M.R.G.M. [CTC]</strong>
        </p>
        <p className="text-sm mt-4 italic">
          P.D. Cuando estés en el punto de inicio sabrás el orden de las
          siguientes letras: T.S.V.E
        </p>
      </div>

      {/* Botón de continuar con degradado dorado */}
      <div className="mt-6 mb-12">
        <ContinueButtonGold setShowPopup={setShowPopup} />
      </div>

      {/* Mostrar el PopUp de contraseña si está abierto */}
      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa la clave"
          placeholderText="Clave"
          buttonText="Verificar"
          error={error}
        />
      )}
    </div>
  );
};

export default IntroClub42;

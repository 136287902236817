// TermsPopup.js
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TimerContext } from "./timerContext_new";

const TermsPopup = ({ onAccept, onClose }) => {
  const { restartTimer } = useContext(TimerContext);

  const handleAccept = () => {
    restartTimer();
    onAccept();
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          Términos y Condiciones
        </h2>
        <p className="text-white mb-4">
          Antes de continuar, debes aceptar los
          <Link
            to="/terms"
            target="_blank"
            className="text-blue-300 underline mx-1"
          >
            términos y condiciones.
          </Link>
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50"
            onClick={handleAccept}
          >
            Aceptar
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50"
            onClick={onClose}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsPopup;

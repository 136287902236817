import React from 'react';
import Slider from 'react-slick';

// Nuevos paquetes de iconos
import paquetedosuno from '../imgs/clientes/paquetedosuno.png';
import paquetedosdos from '../imgs/clientes/paquetedosdos.png';
import paquetedostres from '../imgs/clientes/paquetedostres.png';
import paquetedoscuatro from '../imgs/clientes/paquetedoscuatro.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MobileClientsSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <Slider {...settings}>
        {/* Paquete 1 */}
        <div className="flex justify-center">
          <img src={paquetedosuno} alt="Paquete Uno" className="w-[300px] sm:w-[350px] md:w-[400px]" />
        </div>
        {/* Paquete 2 */}
        <div className="flex justify-center">
          <img src={paquetedosdos} alt="Paquete Dos" className="w-[350px] sm:w-[400px] md:w-[450px]" />
        </div>
        {/* Paquete 3 */}
        <div className="flex justify-center">
          <img src={paquetedostres} alt="Paquete Tres" className="w-[350px] sm:w-[400px] md:w-[450px]" />
        </div>
        {/* Paquete 4 */}
        <div className="flex justify-center">
          <img src={paquetedoscuatro} alt="Paquete Cuatro" className="w-[350px] sm:w-[400px] md:w-[450px]" />
        </div>
      </Slider>
    </div>
  );
};

export default MobileClientsSlider;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../AuthProvider";

const SuccessPopup = ({ onClose, timeElapsed, message, roomId }) => {
  const { currentUser } = useAuth();

  // Función para convertir "MM:SS" a segundos
  const convertTimeToSeconds = (time) => {
    const [minutes, seconds] = time.split(":").map(Number);
    return minutes * 60 + seconds;
  };

  useEffect(() => {
    const saveBestTime = async () => {
      if (currentUser && timeElapsed) {
        const userDocRef = doc(db, roomId, currentUser.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          const timeInSeconds = convertTimeToSeconds(timeElapsed); // Convertir a segundos

          if (userDoc.exists()) {
            const data = userDoc.data();
            const currentBestTime = data.bestTime || Infinity;

            console.log("Tiempo actual en segundos:", timeInSeconds);
            console.log("Mejor tiempo existente en segundos:", currentBestTime);

            // Guarda el nuevo tiempo si es menor que el actual bestTime o si aún no existe bestTime
            if (timeInSeconds < currentBestTime) {
              await updateDoc(userDocRef, { bestTime: timeInSeconds });
              console.log(
                "Mejor tiempo actualizado en Firestore:",
                timeInSeconds
              );
            } else {
              console.log(
                "No se actualizó el tiempo porque el tiempo actual no es mejor."
              );
            }
          } else {
            console.log(
              "Documento de usuario no existe, creando uno nuevo con bestTime."
            );
            await updateDoc(userDocRef, { bestTime: timeInSeconds });
          }
        } catch (error) {
          console.error("Error al actualizar el tiempo en Firestore:", error);
        }
      } else {
        console.log("Usuario no autenticado o tiempo no válido.");
      }
    };

    saveBestTime();
  }, [currentUser, timeElapsed, roomId]);

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        {/* Botón de cierre */}
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          ¡Felicitaciones!
        </h2>
        {/* Muestra el mensaje personalizado que se pasa como prop */}
        <p className="text-white mb-4">{message}</p>
        <p className="text-white mb-6">
          Has completado el desafío en <strong>{timeElapsed}</strong>.
          ¡Increíble!
        </p>

        {/* Botón para volver a la pantalla de inicio */}
        <div className="flex justify-center">
          <Link
            to="/"
            className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80"
          >
            Volver a la pantalla de inicio
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;

// src/components/Component.js
import React, { useState } from "react";

import Header from "./header";
import Footer from "./footer";

import { useAuth } from "../components/AuthProvider"; // Importa useAuth del AuthProvider
import SectionOne from "./sectionOne";
import Testimonials from "./testimonials";
import EscapeRooms from "./escapeRooms";

import "../index.css";
import WhatsAppButton from "./whatsAppButton";

export default function Component() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentUser } = useAuth(); // Usa el contexto de autenticación

  return (
    <div className="flex flex-col min-h-[100dvh] w-full">
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <main
        className={`flex-1 ${
          menuOpen ? "landscape-mobile" : "mt-0"
        } landscape-desktop`}
      >
        <WhatsAppButton />
        <SectionOne />
        <div className="bg-black">
          <div id="escapeRooms">
            <EscapeRooms />
          </div>
          <Testimonials />
        </div>
      </main>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

import React from 'react';

const paciente0 = () => {
  return (
    <div>
      paciente0
    </div>
  );
};

export default paciente0;
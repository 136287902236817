import React, { useState } from "react";
import Header from "./header";
import { ChevronRightIcon } from "./Icons";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "./ui/collapsible";
import Footer from "./footer";
import { faqsBySection } from "../data"; // Importamos el archivo de datos modificado
import "../index.css";
import sherlock from "../imgs/sherlock.webp";

export default function FAQ() {
  const [openSection, setOpenSection] = useState(null); // Para controlar las secciones
  const [openQuestion, setOpenQuestion] = useState(null); // Para controlar las preguntas dentro de las secciones
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleSection = (sectionIndex) => {
    setOpenSection(openSection === sectionIndex ? null : sectionIndex);
  };

  const toggleQuestion = (questionIndex) => {
    setOpenQuestion(openQuestion === questionIndex ? null : questionIndex);
  };

  return (
    <div className="relative w-full" id="faqSection">
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <section
        className={`relative w-full lg:min-h-[280vh] min-h-[300vh] mt-16 py-12 bg-gradient-to-b from-blueCTCfaq to-blueCTC ${
          menuOpen ? "landscape-mobile" : "mt-0"
        } landscape-desktop`}
      >
        <div className="container max-w-full px-4 md:px-6">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2 md:w-[45vw] w-auto">
              <h2 className="text-6xl font-nebulica font-bold tracking-tighter text-white bg-redCTC mb-6">
                PREGUNTAS FRECUENTES
              </h2>
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="md:w-2/3">
              <p className="text-sm md:text-lg text-white font-roboto-mono lg:mx-32 mx-4 text-left">
                Tienes preguntas acerca de nuestros escape-rooms? Consulta
                nuestras preguntas frecuentes por secciones.
              </p>
              <div className="mx-auto max-w-4xl items-start gap-6 py-12 text-white font-roboto-mono mt-5 mb-20">
                {faqsBySection.map((section, sectionIndex) => (
                  <div key={sectionIndex} className="mb-6">
                    {/* Desplegable de la sección */}
                    <Collapsible>
                      <CollapsibleTrigger
                        className="flex w-full items-center justify-between rounded-md bg-muted px-4 py-3 text-lg font-semibold transition-colors hover:bg-muted/80 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring cursor-pointer"
                        onClick={() => toggleSection(sectionIndex)}
                      >
                        <span className="text-white flex-1">
                          {section.title}
                        </span>
                        <span className="ml-4 flex-shrink-0">
                          <ChevronRightIcon
                            className={`text-redCTC h-5 w-5 transition-transform duration-300 ${
                              openSection === sectionIndex ? "rotate-90" : ""
                            }`}
                          />
                        </span>
                      </CollapsibleTrigger>
                      <CollapsibleContent
                        className={`px-4 pt-3 text-muted-foreground transition-max-height duration-500 overflow-hidden ${
                          openSection === sectionIndex
                            ? "max-h-auto"
                            : "max-h-0"
                        }`}
                      >
                        {/* Dentro de cada sección, las preguntas */}
                        <Collapsible className="space-y-4">
                          {section.questions.map((faq, questionIndex) => (
                            <div key={questionIndex}>
                              <CollapsibleTrigger
                                className="flex w-full items-center justify-between rounded-md bg-muted px-4 py-3 text-lg font-semibold transition-colors hover:bg-muted/80 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring cursor-pointer"
                                onClick={() => toggleQuestion(questionIndex)}
                              >
                                <span className="text-white flex-1">
                                  {faq.question}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                  <ChevronRightIcon
                                    className={`text-redCTC h-5 w-5 transition-transform duration-300 ${
                                      openQuestion === questionIndex
                                        ? "rotate-90"
                                        : ""
                                    }`}
                                  />
                                </span>
                              </CollapsibleTrigger>
                              <CollapsibleContent
                                className={`px-4 pt-3 text-muted-foreground transition-max-height duration-500 overflow-hidden ${
                                  openQuestion === questionIndex
                                    ? "max-h-screen"
                                    : "max-h-0"
                                }`}
                              >
                                <p className="text-white">{faq.answer}</p>
                              </CollapsibleContent>
                            </div>
                          ))}
                        </Collapsible>
                      </CollapsibleContent>
                    </Collapsible>
                  </div>
                ))}
              </div>
            </div>
            <div className="md:w-1/3 flex items-center justify-center">
              <img src={sherlock} alt="Sherlock" />
            </div>
          </div>
        </div>
      </section>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { testimonials } from "../data";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="w-full py-16 md:py-24 lg:py-32 bg-gradient-to-b from-blueCTCbg to-redCTC">
      <div className="w-3/4 m-auto">
        <h2 className="text-center text-3xl text-white font-nebulica font-bold tracking-tighter sm:text-5xl">
          TESTIMONIOS
        </h2>
        <div className="mt-20">
          <Slider {...settings}>
            {testimonials.map((t) => (
              <div
                key={t.id}
                className="bg-white rounded-xl flex items-center p-4"
              >
                <div className="flex flex-col md:flex-row space-x-4">
                  <div className="flex items-center space-x-4">
                    <img
                      src={t.img}
                      alt=""
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    <div>
                      <p className="text-xl font-semibold">{t.name}</p>
                      <p className="text-redCTC">
                        <span className="text-black">| </span>
                        {t.cuarto}
                      </p>
                    </div>
                  </div>
                  {/*ESTRELLAS */}
                  <div className="flex items-center mt-2 md:mt-0 relative md:static -left-4 md:left-0">
                    {Array(5)
                      .fill()
                      .map((_, i) => (
                        <span
                          key={i}
                          className={`text-2xl ${
                            i < t.rating ? "text-red-500" : "text-gray-300"
                          }`}
                        >
                          ★
                        </span>
                      ))}
                  </div>
                </div>
                <p className="text-gray-700 mt-2">{t.description}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

import React from 'react';
import Slider from "react-slick";

import paqueteuno from '../imgs/clientes/paqueteuno.png';
import paquetedos from '../imgs/clientes/paquetedos.png';
import paquetetres from '../imgs/clientes/paquetetres.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../slider.css';

const ClientsSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false
  };

  return (
    <div className="clients-slider-container w-full max-w-6xl mx-auto">
      <Slider {...settings}>
        {/* Fila 1 */}
        <div className="flex justify-around items-center flex-wrap">
          <img src={paqueteuno} alt="Hasbro" className="w-[600px]" />
        </div>
        {/* Fila 2 */}
        <div className="flex justify-around items-center flex-wrap mt-2">
          <img src={paquetedos} alt="Hasbro" className="w-[700px]" />
        </div>
        {/* Fila 3 */}
        <div className="flex justify-around items-center flex-wrap">
          <img src={paquetetres} alt="Hasbro" className="w-[700px]" />
        </div>
      </Slider>
    </div>
  );
};

export default ClientsSlider;

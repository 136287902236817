import React, { useState } from 'react';
import Header from './header';
import emailjs from '@emailjs/browser';
import Footer from './footer';

const Contact = ({ onClose }) => {  // Recibe una prop para cerrar el formulario
  const [menuOpen, setMenuOpen] = useState(false); // Estado del menú

  const sendEmail = (event) => {
    event.preventDefault();

    emailjs.sendForm('service_w7mvrpg','template_klqj36l', event.target, '19V2mxU-RwBIVcsAw')
      .then(response => console.log(response))
      .catch(error => console.log(error));
  }

  return (
    <div className='relative w-full h-screen flex justify-center items-center bg-black/50'>
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        {/* Botón de cierre */}
        <button 
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}  // Llama a la función onClose para cerrar el formulario
        >
          &times;
        </button>
        
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">Contáctanos</h2>
        <form onSubmit={sendEmail}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-white">Nombre</label>
            <input type="text" id="name" name="user_name" placeholder="Tu nombre" className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary" required></input>
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-white">Teléfono</label>
            <input type="tel" id="phone" name="user_phone" placeholder="Tu teléfono" className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary" required></input>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
            <input type="email" id="email" name="user_email" placeholder="Tu Email" className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary" required></input>
          </div>
          <div className="mb-4">
            <label htmlFor="event_type" className="block text-sm font-medium text-white">Tipo de evento</label>
            <input type="text" id="event_type" name="event_type" placeholder="Tipo de evento (Ej. Cumpleaños, Empresa)" className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary" required></input>
          </div>
          <div className="mb-4">
            <label htmlFor="people_count" className="block text-sm font-medium text-white">Número aproximado de personas</label>
            <input type="number" id="people_count" name="people_count" placeholder="Número de personas" className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary" required></input>
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block text-sm font-medium text-white">Mensaje</label>
            <textarea id="message" name="user_message" rows="3" placeholder="Tu Mensaje" className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary" required></textarea>
          </div>
          <button type="submit" className="w-full bg-redCTC text-primary-foreground py-2 px-4 rounded-md hover:bg-primary/80 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50 text-white">Enviar Mensaje</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fondoClub42 from "../imgs/cuartos/club42/fondo_club42.webp";
import logoClub42 from "../imgs/cuartos/club42/logo.png";
import PasswordPopup from "./popUps/passwordPopup";
import { TimerContext } from "./timerContext_new"; // Importa el contexto
import timeRedIcon from "../imgs/timer-red.svg";
import msj1 from "../imgs/cuartos/club42/msj_1.png";
import msj2 from "../imgs/cuartos/club42/msj_2.png";
import msj3 from "../imgs/cuartos/club42/msj_3.png";
import msj4 from "../imgs/cuartos/club42/msj_4.png";
import msj5 from "../imgs/cuartos/club42/msj_5.png";
import msj6 from "../imgs/cuartos/club42/msj_6.png";
import msj7 from "../imgs/cuartos/club42/msj_7.png";
import msj8 from "../imgs/cuartos/club42/msj_8.png";
import msj9 from "../imgs/cuartos/club42/msj_9.png";
import msj10 from "../imgs/cuartos/club42/msj_10.png";
import msj11 from "../imgs/cuartos/club42/msj_11.png";
import msjEscribiendo from "../imgs/cuartos/club42/msj_escribiendo.svg";

import sendArrow from "../imgs/cuartos/club42/teclado/send_arrow.svg";

// Teclado y otros botones
import shiftIcon from "../imgs/cuartos/club42/teclado/shift.svg";
import numberButtonIcon from "../imgs/cuartos/club42/teclado/numberbutton.svg";
import microphoneIcon from "../imgs/cuartos/club42/teclado/microphone.svg";
import graymicrophoneIcon from "../imgs/cuartos/club42/teclado/graymicrophone.svg";

import deleteIcon from "../imgs/cuartos/club42/teclado/delete.svg";
import introIcon from "../imgs/cuartos/club42/teclado/intro.svg";
import spaceBarIcon from "../imgs/cuartos/club42/teclado/spacebar.svg";

// Letras del teclado
import aIcon from "../imgs/cuartos/club42/teclado/A.svg";
import bIcon from "../imgs/cuartos/club42/teclado/B.svg";
import cIcon from "../imgs/cuartos/club42/teclado/C.svg";
import dIcon from "../imgs/cuartos/club42/teclado/D.svg";
import eIcon from "../imgs/cuartos/club42/teclado/E.svg";
import fIcon from "../imgs/cuartos/club42/teclado/F.svg";
import gIcon from "../imgs/cuartos/club42/teclado/G.svg";
import hIcon from "../imgs/cuartos/club42/teclado/H.svg";
import iIcon from "../imgs/cuartos/club42/teclado/I.svg";
import jIcon from "../imgs/cuartos/club42/teclado/J.svg";
import kIcon from "../imgs/cuartos/club42/teclado/K.svg";
import lIcon from "../imgs/cuartos/club42/teclado/L.svg";
import mIcon from "../imgs/cuartos/club42/teclado/M.svg";
import nIcon from "../imgs/cuartos/club42/teclado/N.svg";
import oIcon from "../imgs/cuartos/club42/teclado/O.svg";
import pIcon from "../imgs/cuartos/club42/teclado/P.svg";
import qIcon from "../imgs/cuartos/club42/teclado/Q.svg";
import rIcon from "../imgs/cuartos/club42/teclado/R.svg";
import sIcon from "../imgs/cuartos/club42/teclado/S.svg";
import tIcon from "../imgs/cuartos/club42/teclado/T.svg";
import uIcon from "../imgs/cuartos/club42/teclado/U.svg";
import vIcon from "../imgs/cuartos/club42/teclado/V.svg";
import wIcon from "../imgs/cuartos/club42/teclado/W.svg";
import xIcon from "../imgs/cuartos/club42/teclado/X.svg";
import yIcon from "../imgs/cuartos/club42/teclado/Y.svg";
import zIcon from "../imgs/cuartos/club42/teclado/Z.svg";
import puntoIcon from "../imgs/cuartos/club42/teclado/Point.svg";

const Sala19 = () => {
  const [inputText, setInputText] = useState("");
  const [micIcon, setMicIcon] = useState(graymicrophoneIcon); // Estado para controlar el ícono del micrófono

  const [messages, setMessages] = useState([
    { image: msj1, alignment: "right", maxWidth: "200px" },
    { image: msj2, alignment: "left", maxWidth: "300px" },
    { image: msj3, alignment: "left", maxWidth: "190px" },
    { image: msj4, alignment: "left", maxWidth: "220px" },
    { image: msj5, alignment: "left", maxWidth: "190px" },
  ]);

  const handleKeyPress = (letter) => {
    setInputText((prev) => prev + letter);
  };

  const handleDelete = () => {
    setInputText((prev) => prev.slice(0, -1));
  };

  const handleSend = () => {
    if (inputText.toLowerCase() === "river") {
      setMessages((prevMessages) => [
        ...prevMessages,
        { image: msj6, alignment: "right", maxWidth: "100px" },
        { image: msjEscribiendo, alignment: "left", maxWidth: "120px" },
      ]);
      setMicIcon(microphoneIcon);

      const delayedMessages = [
        { image: msj7, alignment: "left", maxWidth: "120px" },
        { image: msj8, alignment: "left", maxWidth: "220px" },
        { image: msj9, alignment: "left", maxWidth: "250px" },
        { image: msj10, alignment: "left", maxWidth: "250px" },
        { image: msj11, alignment: "left", maxWidth: "280px" },
      ];

      delayedMessages.forEach((msg, index) => {
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            msg,
            { image: msjEscribiendo, alignment: "left", maxWidth: "100px" },
          ]);
        }, (index + 1) * 1000);
      });
      setTimeout(() => {
        setMessages((prevMessages) => prevMessages.slice(0, -1));
      }, delayedMessages.length * 1000 + 1);
    }
    setInputText("");
  };

  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { timeLeft } = useContext(TimerContext);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePasswordSubmit = (password) => {
    if (password === "STEV") {
      // Verifica la contraseña
      setShowPopup(false);
      setError("");
      navigate("/nextpage");
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  const renderMessageImage = (
    image,
    alignment = "left",
    maxWidth = "300px"
  ) => {
    return (
      <div
        className={`flex ${
          alignment === "right" ? "justify-end" : "justify-start"
        } my-2`}
      >
        <img
          src={image}
          alt="Mensaje"
          className="h-auto"
          style={{ maxWidth }}
        />
      </div>
    );
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center text-white"
      style={{
        backgroundImage: `url(${fondoClub42})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {/* Logo */}
      <div className="my-12">
        <img src={logoClub42} alt="Club 42 Logo" className="w-48 h-auto" />
      </div>

      {/* Mensajes */}
      <div className="flex flex-col md  :p-8 p-2 min-w-[100vw] sm:min-w-[90vw] md:min-w-[70vw] xl:min-w-[40vw] min-h-[60vh]">
        {messages.map((msg, index) =>
          renderMessageImage(msg.image, msg.alignment, msg.maxWidth)
        )}
      </div>

      {/* Botón de continuar con degradado dorado */}
      <div className="mt-6 mb-12">
        {/* Barra de texto */}
        <div className="flex items-center w-full px-4 mb-4">
          <input
            type="text"
            value={inputText}
            readOnly
            className="flex-1 p-2 text-black bg-white rounded-md"
          />
          <button onClick={handleSend} className="ml-2">
            <img src={sendArrow} alt="Enviar" className="w-10 h-10" />
          </button>
        </div>

        {/* Teclado */}
        <div className="bg-gray-800 p-4 rounded-lg flex flex-col justify-center mx-4">
          <div className="flex justify-center space-x-1">
            {/* Primera fila de letras */}
            <button onClick={() => handleKeyPress("Q")}>
              <img src={qIcon} alt="Q" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("W")}>
              <img src={wIcon} alt="W" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("E")}>
              <img src={eIcon} alt="E" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("R")}>
              <img src={rIcon} alt="R" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("T")}>
              <img src={tIcon} alt="T" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("Y")}>
              <img src={yIcon} alt="Y" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("U")}>
              <img src={uIcon} alt="U" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("I")}>
              <img src={iIcon} alt="I" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("O")}>
              <img src={oIcon} alt="O" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("P")}>
              <img src={pIcon} alt="P" className="w-12 h-12 m-1" />
            </button>
          </div>
          <div className="flex justify-center space-x-1">
            {/* Segunda fila de letras */}
            <button onClick={() => handleKeyPress("A")}>
              <img src={aIcon} alt="A" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("S")}>
              <img src={sIcon} alt="S" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("D")}>
              <img src={dIcon} alt="D" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("F")}>
              <img src={fIcon} alt="F" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("G")}>
              <img src={gIcon} alt="G" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("H")}>
              <img src={hIcon} alt="H" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("J")}>
              <img src={jIcon} alt="J" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("K")}>
              <img src={kIcon} alt="K" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("L")}>
              <img src={lIcon} alt="L" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress(".")}>
              <img src={puntoIcon} alt="Punto" className="w-12 h-12 m-1" />
            </button>
          </div>
          <div className="flex justify-center space-x-1">
            {/* Tercera fila de letras */}
            <button>
              <img src={shiftIcon} alt="Shift" className="w-auto h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("Z")}>
              <img src={zIcon} alt="Z" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("X")}>
              <img src={xIcon} alt="X" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("C")}>
              <img src={cIcon} alt="C" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("V")}>
              <img src={vIcon} alt="V" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("B")}>
              <img src={bIcon} alt="B" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("N")}>
              <img src={nIcon} alt="N" className="w-12 h-12 m-1" />
            </button>
            <button onClick={() => handleKeyPress("M")}>
              <img src={mIcon} alt="M" className="w-12 h-12 m-1" />
            </button>
            <button onClick={handleDelete}>
              <img src={deleteIcon} alt="Borrar" className="w-auto h-12 m-1" />
            </button>
          </div>
          <div className="flex justify-center sm:space-x-3.5 space-x-1">
            {/* Fila de botones especiales */}
            <button>
              <img
                src={numberButtonIcon}
                alt="Números"
                className="w-auto h-12"
              />
            </button>
            <button>
              <img src={micIcon} alt="Micrófono" className="w-auto h-12" />
            </button>
            <button onClick={() => handleKeyPress(" ")}>
              <img src={spaceBarIcon} alt="Espacio" className="w-auto h-12" />
            </button>
            <button>
              <img src={introIcon} alt="Intro" className="w-auto h-12" />
            </button>
          </div>
        </div>
      </div>

      {/* Mostrar el PopUp de contraseña si está abierto */}
      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa la clave"
          placeholderText="Clave"
          buttonText="Verificar"
          error={error}
        />
      )}
    </div>
  );
};

export default Sala19;

import React from "react";
import { Link } from "react-router-dom";
import escapeRoomImg from "../imgs/fondo-section-one.webp";
import flechaAbajo from "../imgs/Flecha-abajo.svg";
import { TypeAnimation } from "react-type-animation";
import candado from "../imgs/candado-white.svg";

const SectionOne = () => {
  return (
    <section
      className="relative w-full md:h-[92.25vh] h-[100vh] landscape:h-[100vh]"
      style={{
        backgroundImage: `url(${escapeRoomImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-center text-white px-4 md:px-6">
        <h1 className="text-3xl font-nebulica font-medium tracking-tighter sm:text-5xl md:text-7xl">
          Escapa
        </h1>
        <h1 className="text-3xl font-nebulica font-medium bg-redCTC tracking-tighter sm:text-5xl md:text-7xl mb-11">
          <TypeAnimation
            sequence={[
              "del aburrimiento",
              1000,
              "al entretenimiento",
              1000,
              "de la rutina",
              1000,
              "a lo nuevo",
              1000,
              "de lo ordinario",
              1000,
              "a lo extraordinario",
              1000,
              "de los mismos planes",
              1000,
              "a nuevas experiencias",
              1000,
              "del promedio",
              1000,
              "a ser un crack",
              1000,
            ]}
            wrapper="span"
            speed={50}
            repeat={Infinity}
            className="block font-bold text-secondary"
          />
        </h1>
        <div className="pt-4">
          <img
            src={flechaAbajo}
            className="animate-bounce h-14 w-14"
            alt="Flecha abajo"
          />
        </div>
        <a
          href="#escapeRooms"
          className="inline-flex h-14 w-64 items-center justify-center rounded-md bg-gradient-to-b from-redCTC to-redCTCbuttons text-xl font-roboto-mono font-semibold px-6 mt-11"
        >
          <img src={candado} alt="Candado" className="h-10 w-10 mr-4" />
          JUEGA AHORA
        </a>
      </div>
    </section>
  );
};

export default SectionOne;

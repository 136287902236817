import React, { useState } from "react";
import Header from "./header";
import { ChevronRightIcon } from "./Icons";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "./ui/collapsible";
import Footer from "./footer";

export default function Privacy() {
  const [openCollapsible, setOpenCollapsible] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // Estado del menú

  const toggleCollapsible = (index) => {
    setOpenCollapsible(openCollapsible === index ? null : index);
  };

  return (
    <div className={`flex flex-col w-full min-h-[100dvh] ${menuOpen ? 'mt-[40vh]' : 'mt-0'} transition-all duration-300`}>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} /> {/* Pasar el estado del menú al Header */}
      <section className="w-full h-[92.25vh] py-12 bg-blueCTC/95">
        <div className="container max-w-full px-4 md:px-6">
            <div className="mx-auto max-w-3xl space-y-6">
                <div className="space-y-2">
                    <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-8 text-redCTC font-nebulica">Política de Privacidad</h2>
                    <p className="text-muted-foreground md:text-xl text-white font-roboto-mono">
                        Tu privacidad es importante para nosotros. Esta política de privacidad explica qué datos recopilamos, cómo los utilizamos y cómo protegemos tu información.
                    </p>
                </div>
                <Collapsible className="space-y-4">
                <div>
                    <CollapsibleTrigger
                    className="flex w-full items-center justify-between rounded-md bg-muted px-4 py-3 text-sm font-medium transition-colors hover:bg-muted/80 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                    onClick={() => toggleCollapsible(1)}
                    >
                    <span className="text-white font-roboto-mono">Recopilación de Información</span>
                    <ChevronRightIcon className={`text-redCTC h-5 w-5 transition-transform duration-300 ${openCollapsible === 1 ? "rotate-90" : ""}`} />
                    </CollapsibleTrigger>
                    <CollapsibleContent className={`px-4 pt-3 text-sm text-muted-foreground transition-max-height duration-500 overflow-hidden ${openCollapsible === 1 ? "max-h-screen" : "max-h-0"}`}>
                    <p className="text-white font-roboto-mono">
                        Recopilamos información que nos proporcionas directamente, como tu nombre, dirección de correo electrónico y cualquier otra información que decidas compartir con nosotros. También recopilamos información automáticamente a través de tu uso de nuestra plataforma, como tu dirección IP y datos de navegación.
                    </p>
                    </CollapsibleContent>
                </div>
                <div>
                    <CollapsibleTrigger
                    className="flex w-full items-center justify-between rounded-md bg-muted px-4 py-3 text-sm font-medium transition-colors hover:bg-muted/80 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                    onClick={() => toggleCollapsible(2)}
                    >
                    <span className="text-white font-roboto-mono">Uso de la Información</span>
                    <ChevronRightIcon className={`text-redCTC h-5 w-5 transition-transform duration-300 ${openCollapsible === 2 ? "rotate-90" : ""}`} />
                    </CollapsibleTrigger>
                    <CollapsibleContent className={`px-4 pt-3 text-sm text-muted-foreground transition-max-height duration-500 overflow-hidden ${openCollapsible === 2 ? "max-h-screen" : "max-h-0"}`}>
                    <p className="text-white font-roboto-mono">
                        Utilizamos la información que recopilamos para proporcionarte nuestros servicios, mejorar nuestra plataforma, y comunicarnos contigo. También podemos utilizar tu información para personalizar tu experiencia y ofrecerte contenido y anuncios relevantes.
                    </p>
                    </CollapsibleContent>
                </div>
                <div>
                    <CollapsibleTrigger
                    className="flex w-full items-center justify-between rounded-md bg-muted px-4 py-3 text-sm font-medium transition-colors hover:bg-muted/80 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                    onClick={() => toggleCollapsible(3)}
                    >
                    <span className="text-white font-roboto-mono">Compartición de la Información</span>
                    <ChevronRightIcon className={`text-redCTC h-5 w-5 transition-transform duration-300 ${openCollapsible === 3 ? "rotate-90" : ""}`} />
                    </CollapsibleTrigger>
                    <CollapsibleContent className={`px-4 pt-3 text-sm text-muted-foreground transition-max-height duration-500 overflow-hidden ${openCollapsible === 3 ? "max-h-screen" : "max-h-0"}`}>
                    <p className="text-white font-roboto-mono">
                        No compartimos tu información personal con terceros, excepto cuando es necesario para proporcionarte nuestros servicios, cumplir con la ley, o proteger nuestros derechos. Podemos compartir información no personal agregada con nuestros socios y anunciantes.
                    </p>
                    </CollapsibleContent>
                </div>
                <div>
                    <CollapsibleTrigger
                    className="flex w-full items-center justify-between rounded-md bg-muted px-4 py-3 text-sm font-medium transition-colors hover:bg-muted/80 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                    onClick={() => toggleCollapsible(4)}
                    >
                    <span className="text-white font-roboto-mono">Seguridad de la Información</span>
                    <ChevronRightIcon className={`text-redCTC h-5 w-5 transition-transform duration-300 ${openCollapsible === 4 ? "rotate-90" : ""}`} />
                    </CollapsibleTrigger>
                    <CollapsibleContent className={`px-4 pt-3 text-sm text-muted-foreground transition-max-height duration-500 overflow-hidden ${openCollapsible === 4 ? "max-h-screen" : "max-h-0"}`}>
                    <p className="text-white font-roboto-mono">
                        Implementamos medidas de seguridad técnicas y organizativas para proteger tu información contra el acceso no autorizado, la alteración, la divulgación o la destrucción. Sin embargo, ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro.
                    </p>
                    </CollapsibleContent>
                </div>
                <div>
                    <CollapsibleTrigger
                    className="flex w-full items-center justify-between rounded-md bg-muted px-4 py-3 text-sm font-medium transition-colors hover:bg-muted/80 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                    onClick={() => toggleCollapsible(5)}
                    >
                    <span className="text-white font-roboto-mono">Tus Derechos</span>
                    <ChevronRightIcon className={`text-redCTC h-5 w-5 transition-transform duration-300 ${openCollapsible === 5 ? "rotate-90" : ""}`} />
                    </CollapsibleTrigger>
                    <CollapsibleContent className={`px-4 pt-3 text-sm text-muted-foreground transition-max-height duration-500 overflow-hidden ${openCollapsible === 5 ? "max-h-screen" : "max-h-0"}`}>
                    <p className="text-white font-roboto-mono">
                        Tienes derecho a acceder, corregir o eliminar tu información personal que poseemos. Si deseas ejercer estos derechos, por favor contáctanos a través de la información de contacto proporcionada en nuestra plataforma.
                    </p>
                    </CollapsibleContent>
                </div>
                </Collapsible>
            </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

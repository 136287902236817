import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PasswordPopup from "./popUps/passwordPopup";
import timeRedIcon from "../imgs/timer-red.svg"; // Importamos el icono del temporizador
import backgroundImage from "../imgs/people_background.webp"; // Importamos la imagen de fondo

// Importa el timer
import { TimerContext } from "./timerContext_new";

// Firebase
import { useAuth } from "./AuthProvider";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentPopup from "./popUps/paymentPopup";

const stripePromise = loadStripe(
  "pk_test_51QAu9FDuRrzBMoi3Snxx7GBymTp4qcMxupQPDkKI8CsZ9dRgt3T5YWk8QyGzATvEpUiRizh4EXTk4wGFFhoWG6rK00VdtxGk1E"
);

const BlackBigBrother = () => {
  const [showPopup, setShowPopup] = useState(false); // Controla si el PopUp está abierto
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [error, setError] = useState(""); // Controla los errores
  const [hasPaid, setHasPaid] = useState(false);
  const navigate = useNavigate();

  const { timeLeft } = useContext(TimerContext);
  const { currentUser } = useAuth();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (currentUser) {
        const docRef = doc(db, "big_brother", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const userHasPaid = data.hasPaid || false;
          setHasPaid(userHasPaid);
          setShowPaymentPopup(!userHasPaid);
        } else {
          setShowPaymentPopup(true);
        }
      }
    };

    checkPaymentStatus();
  }, [currentUser]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // Maneja la validación de la contraseña y actualización de Firestore
  const handlePasswordSubmit = async (password) => {
    if (password === "Eric") {
      console.log("Contraseña correcta. Procediendo a actualizar la fase...");

      try {
        // Obtiene el documento del usuario para verificar el currentPhase actual
        const userDocRef = doc(db, "big_brother", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const initialPhase = userDocSnap.data().currentPhase || 0;
          console.log("Fase actual antes de actualizar:", initialPhase);

          // Actualiza currentPhase a 3 en Firestore
          await updateDoc(userDocRef, { currentPhase: 3 });
          console.log("Fase actualizada a 3 en Firestore.");

          // Verifica si la fase se actualizó correctamente
          const updatedDocSnap = await getDoc(userDocRef);
          const updatedPhase = updatedDocSnap.data().currentPhase || 0;
          console.log("Fase actual después de actualizar:", updatedPhase);

          if (updatedPhase === 3) {
            // Solo navega si la fase fue actualizada correctamente
            console.log("Navegando a /BigBrother en 3 segundos...");
            setTimeout(() => {
              setShowPopup(false);
              navigate("/BigBrother");
            }, 3000);
          } else {
            console.error(
              "Error: La fase no se actualizó correctamente en Firestore."
            );
            setError(
              "Hubo un problema al actualizar la fase. Intenta nuevamente."
            );
          }
        } else {
          console.error(
            "Error: Documento del usuario no encontrado en Firestore."
          );
          setError("Hubo un problema al obtener los datos del usuario.");
        }
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      console.log("Contraseña incorrecta:", password);
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-blueCTCbg bg-cover bg-top bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="text-black text-4xl mb-8">
        {/* Muestra el temporizador en la pantalla */}
        <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
      </div>
      {showPaymentPopup && !hasPaid && (
        <Elements stripe={stripePromise}>
          <PaymentPopup
            onClose={() => {
              setShowPaymentPopup(false);
              setHasPaid(true); // Actualiza el estado para indicar que el pago se ha realizado
            }}
            collectionName="big_brother"
            phaseNumber={2}
          />
        </Elements>
      )}
      {hasPaid && (
        <div className="classname">
          <PasswordPopup
            onSubmit={handlePasswordSubmit} // Pasamos la función para verificar la contraseña
            onClose={() => setShowPopup(false)} // Cierra el PopUp si es necesario
            titleText="Ingresa la llave"
            placeholderText="Llave"
            buttonText="Verificar llave"
            error={error} // Pasamos el error si hay uno
          />
        </div>
      )}
    </div>
  );
};

export default BlackBigBrother;

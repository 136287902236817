// src/components/Profile.js
import React from 'react';
import { useAuth } from './AuthProvider';

const Profile = () => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-2xl font-bold">Perfil del Usuario</h1>
      <div className="mt-4">
        <p><strong>Email:</strong> {currentUser.email}</p>
        <p><strong>Fecha de creación:</strong> {new Date(currentUser.metadata.creationTime).toLocaleDateString()}</p>
      </div>
    </div>
  );
};

export default Profile;

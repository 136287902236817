import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";

// Importa el timer
import { TimerContext } from "./timerContext_new";

import { useAuth } from "./AuthProvider";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase"; //Configuración de Firebase

// Importa las imágenes
import fondo3 from "../imgs/cuartos/asesinoimitador/fondo3.webp";
import disc from "../imgs/cuartos/asesinoimitador/disc.webp";
import safebox from "../imgs/cuartos/asesinoimitador/cajafuerte.webp";
import book from "../imgs/cuartos/asesinoimitador/book.svg";
import roca from "../imgs/cuartos/asesinoimitador/roca.webp";
import closeIcon from "../imgs/close.svg";
import timeRedIcon from "../imgs/timer-red.svg";

const AsesinoImitador3 = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [backgroundSize, setBackgroundSize] = useState("cover");
  const [error, setError] = useState("");
  const [isZoomed, setIsZoomed] = useState(false);
  const navigate = useNavigate();
  const { timeLeft } = useContext(TimerContext);
  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const verifyClave = async (clave) => {
    if (clave === "181974") {
      try {
        if (currentUser) {
          // Referencia al documento del usuario en la colección correspondiente
          const userDocRef = doc(db, "asesino_imitador", currentUser.uid);

          // Actualizar la currentPhase a 4
          await updateDoc(userDocRef, { currentPhase: 4 });
          console.log("Fase actualizada a 4 en Firestore");
        }

        setTimeout(() => {
          setShowPopup(false);
          navigate("/interiorcajafuerte"); // Navega a la siguiente pantalla
        }, 4500); // Espera 3 segundos antes de redirigir
      } catch (error) {
        console.error("Error al actualizar la fase:", error);
        setError("Hubo un problema al actualizar la fase. Intenta de nuevo.");
      }
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  // useEffect para ajustar el tamaño de fondo dinámicamente según la pantalla
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setBackgroundSize("cover");
      } else {
        setBackgroundSize("cover");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Ejecutar cuando el componente se monta

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //CurrentPhase
  useEffect(() => {
    const setInitialPhase = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "asesino_imitador", currentUser.uid);
        await setDoc(userDocRef, { currentPhase: 3 }, { merge: true });
      }
    };
    setInitialPhase();
  }, [currentUser]);

  const handleZoomClick = () => {
    setIsZoomed(true);
  };

  const handleCloseZoom = () => {
    setIsZoomed(false);
  };

  return (
    <div
      className="h-auto min-h-[100vh] flex"
      style={{
        backgroundImage: `url(${fondo3})`,
        backgroundSize: backgroundSize,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="m-auto w-screen my-6 p-6 h-auto rounded-lg bg-blueCTC/20 shadow-lg text-white mx-8">
        {/* Temporizador en la pantalla */}
        <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
        <div className="flex flex-col lg:flex-row md:space-x-4">
          <div className="w-full flex flex-col items-center justify-center">
            <div
              className="bg-cover p-4 text-black md:text-lg lg:text-xl font-roboto-mono mb-4"
              style={{
                backgroundImage: `url(${roca})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
              }}
            >
              <p className="mb-4">
                Han encontrado la casa del asesino imitador.
              </p>
              <p className="mb-4">
                Todo indica que su partida fue apresurada; ha dejado todas sus
                pertenencias atrás.
              </p>
              <p className="mb-4">
                Al inspeccionar el lugar, descubren tres objetos intrigantes:{" "}
                <strong>un libro, un disco de vinilo y una caja fuerte,</strong>{" "}
                asegurada con un código de <strong>6 dígitos</strong>.
              </p>
            </div>
            <div className="flex flex-col lg:flex-row gap-8">
              <img
                className="h-40 sm:h-56 xl:h-96 w-auto"
                src={disc}
                alt="Imagen del disco"
              />
              <img
                className="h-40 sm:h-56 xl:h-96 w-auto cursor-pointer"
                src={safebox}
                alt="Imagen de la caja fuerte"
                onClick={handleZoomClick}
              />
              <img
                className="h-40 sm:h-56 xl:h-96 w-auto"
                src={book}
                alt="Imagen del libro"
              />
            </div>
          </div>
        </div>

        <ContinueButton setShowPopup={setShowPopup} />

        {showPopup && (
          <PasswordPopup
            onSubmit={verifyClave}
            onClose={() => setShowPopup(false)}
            titleText="Ingresa el pin de la caja fuerte"
            placeholderText="Pin"
            buttonText="Verificar Pin"
            error={error}
          />
        )}

        {/* Vista de zoom de la caja fuerte */}
        {isZoomed && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
            <div className="relative">
              <img
                className="w-auto h-auto max-h-[80vh] max-w-[90vw] rounded-lg"
                src={safebox}
                alt="Caja fuerte en zoom"
              />
              {/* Botón para cerrar el zoom */}
              <button
                className="absolute top-2 right-2 bg-white rounded-full p-2"
                onClick={handleCloseZoom}
              >
                <img src={closeIcon} alt="Cerrar zoom" className="h-6 w-6" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AsesinoImitador3;

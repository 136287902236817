import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";

import { AuthProvider } from "./components/AuthProvider";
import { TimerProvider } from "./components/timerContext_new";
import Loader from "./components/loader";
import Component from "./components/Component";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Terms from "./components/terms";
import Privacy from "./components/privacy";
import Profile from "./components/Profile";
import AboutUs from "./components/aboutUs";
import Testimonials from "./components/testimonials";
import EscapeRooms from "./components/escapeRooms";
import FlipCards from "./components/FlipCards";
import FAQ from "./components/faq";
import Contact from "./components/contact";

import AsesinoImitador from "./components/introasesinoImitador";
import AsesinoImitador2 from "./components/paquete";
import AsesinoImitador3 from "./components/casainterior";
import AsesinoImitador4 from "./components/interiorcajafuerte";

import IntroClub42 from "./components/IntroClub42";
import Sala19 from "./components/sala19";

import Paciente0 from "./components/paciente0";
import Puerta33 from "./components/puerta33";
import AudioAnalyzer from "./components/audio";

import IntroBigBrother from "./components/IntroBigBrother";
import BigBrother from "./components/BigBrother";
import BlackBigBrother from "./components/BlackBigBrother";
import PuntoPuntoPunto from "./components/puntopuntopunto";

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const routesWithLoader = useMemo(
    () => [
      "/introasesinoImitador",
      "/paquete",
      "/casainterior",
      "/interiorcajafuerte",
      "/IntroBigBrother",
      "/BlackBigBrother",
      "/BigBrother",
      "/PuntoPuntoPunto",
      "/IntroClub42",
      "/Sala19",
      "/paciente0",
      "/puerta33",
      "/aboutUs",
      "/profile",
      "/",
    ],
    []
  );

  useEffect(() => {
    // Verifica si la ruta actual está en el array de rutas con loader
    if (routesWithLoader.includes(location.pathname)) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 3000); // Muestra el loader por 1 segundo, ajusta el tiempo si es necesario
      return () => clearTimeout(timer);
    } else {
      setLoading(false);
    }
  }, [location.pathname, routesWithLoader]);

  return (
    <AuthProvider>
      <TimerProvider>
        {loading && <Loader />}
        {/* Muestra el loader solo si está en las rutas especificadas */}
        <Routes>
          <Route path="/" element={<Component />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/escapeRooms" element={<EscapeRooms />} />
          <Route path="/flipCards" element={<FlipCards />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/audio" element={<AudioAnalyzer />} />

          <Route path="/introasesinoImitador" element={<AsesinoImitador />} />
          <Route
            path="/paquete"
            element={
              <ProtectedRoute requiredPhase={2} roomId="asesino_imitador">
                <AsesinoImitador2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/casainterior"
            element={
              <ProtectedRoute requiredPhase={3} roomId="asesino_imitador">
                <AsesinoImitador3 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/interiorcajafuerte"
            element={
              <ProtectedRoute requiredPhase={4} roomId="asesino_imitador">
                <AsesinoImitador4 />
              </ProtectedRoute>
            }
          />

          {/* Rutas protegidas para "Big Brother" */}
          <Route path="/IntroBigBrother" element={<IntroBigBrother />} />
          <Route
            path="/BlackBigBrother"
            element={
              <ProtectedRoute requiredPhase={2} roomId="big_brother">
                <BlackBigBrother />
              </ProtectedRoute>
            }
          />
          <Route
            path="/BigBrother"
            element={
              <ProtectedRoute requiredPhase={3} roomId="big_brother">
                <BigBrother />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PuntoPuntoPunto"
            element={
              <ProtectedRoute requiredPhase={4} roomId="big_brother">
                <PuntoPuntoPunto />
              </ProtectedRoute>
            }
          />

          <Route path="/IntroClub42" element={<IntroClub42 />} />
          <Route path="/Sala19" element={<Sala19 />} />

          <Route path="/paciente0" element={<Paciente0 />} />
          <Route path="/puerta33" element={<Puerta33 />} />
        </Routes>
      </TimerProvider>
    </AuthProvider>
  );
}

export default App;

import { useRef, useState, useEffect } from 'react';

export default function SocialMediaIcon({ children, text, color, ...props }) {
  const [hovered, setHovered] = useState(false);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [hovered]);

  return (
    <button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`flex p-2 items-center rounded-lg text-white ${color || "bg-black"}`}
      {...props}
    >
      {children}
      <div
        style={{ width: hovered ? ref.current?.offsetWidth || 0 : 0}}
        className="overflow-x-hidden transition-all duration-300 ease-out">
        <span ref={ref} className="px-1.5">{text}</span>
      </div>
    </button>
  );
}

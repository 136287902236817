import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthProvider";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

// ==============Badges===============
import asesinobadge from "../../imgs/badges/asesino-badge.svg";
import asesinogoldbadge from "../../imgs/badges/asesino-gold-badge.svg";
import brotherbadge from "../../imgs/badges/brother-badge.svg";
import brothergoldbadge from "../../imgs/badges/brother-gold-badge.svg";
import c42badge from "../../imgs/badges/club42-badge.svg";
import c42goldbadge from "../../imgs/badges/club42-gold-badge.svg";
import paciente0badge from "../../imgs/badges/paciente0-badge.svg";
import paciente0goldbadge from "../../imgs/badges/paciente0-gold-badge.svg";
import vuelosecretobadge from "../../imgs/badges/vuelo-secreto-badge.svg";
import vuelosecretogoldbadge from "../../imgs/badges/vuelo-secreto-gold-badge.svg";
import dorsobadge from "../../imgs/badges/dorso-badge.svg";
import dorsogoldbadge from "../../imgs/badges/dorso-gold-badge.svg";

const ProfilePopup = ({ onClose, onDeleteAccount }) => {
  const { currentUser } = useAuth();
  const [badges, setBadges] = useState({});

  useEffect(() => {
    if (!currentUser) return;

    const loadBadges = async () => {
      const rooms = ["asesino_imitador", "big_brother", "c42"]; // Agrega más rooms si es necesario
      const newBadges = {};

      for (const room of rooms) {
        const hasBestTime = await checkBestTime(room);
        newBadges[room] = hasBestTime;
      }

      setBadges(newBadges);
    };

    loadBadges();
  }, [currentUser]);

  if (!currentUser) {
    return <p>Cargando...</p>;
  }

  const userName = currentUser.firstName || "Nombre no disponible";
  const userLastName = currentUser.lastName || "Apellido no disponible";
  const userEmail = currentUser.email;
  const creationDate = new Date(
    currentUser.metadata.creationTime
  ).toLocaleDateString();

  // Función para obtener el badge adecuado
  const getBadge = (badge, goldBadge, condition) => {
    return condition ? goldBadge : badge;
  };

  // Función para verificar si el usuario tiene un "bestTime" en el cuarto
  const checkBestTime = async (roomId) => {
    try {
      const userDocRef = doc(db, roomId, currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      return userDocSnap.exists() && userDocSnap.data().bestTime != null;
    } catch (error) {
      console.error(`Error al obtener bestTime para ${roomId}:`, error);
      return false;
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        {/* Botón de cierre */}
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <div className="flex flex-col items-center text-white mb-4">
          {/* User Avatar */}
          <div className="w-16 h-16 bg-gray-500 rounded-full flex items-center justify-center text-xl font-bold mb-4">
            {userName.charAt(0)}
            {userLastName.charAt(0)}
          </div>

          {/* User Name */}
          <h2 className="text-2xl font-bold">
            {userName} {userLastName}
          </h2>
          <p className="text-sm text-gray-300">{userEmail}</p>
        </div>

        {/* Badges Section */}
        <div className="flex justify-center space-x-2 my-4">
          <img
            src={getBadge(
              asesinobadge,
              asesinogoldbadge,
              badges["asesino_imitador"] || false
            )}
            alt="Asesino Badge"
            className="w-10 h-10"
          />
          <img
            src={getBadge(
              brotherbadge,
              brothergoldbadge,
              badges["big_brother"] || false
            )}
            alt="Big Brother Badge"
            className="w-10 h-10"
          />
          <img
            src={getBadge(c42badge, c42goldbadge, badges["c42"] || false)}
            alt="C42 Badge"
            className="w-10 h-10"
          />
          <img
            src={getBadge(
              paciente0badge,
              paciente0goldbadge,
              badges["paciente0"] || false
            )}
            alt="Paciente 0 Badge"
            className="w-10 h-10"
          />
          <img
            src={getBadge(
              vuelosecretobadge,
              vuelosecretogoldbadge,
              badges["vuelo_secreto"] || false
            )}
            alt="Vuelo Secreto Badge"
            className="w-10 h-10"
          />
          {/* Añade más badges si es necesario */}
        </div>

        {/* Profile Details Section */}
        <div className="text-white mt-4">
          <h3 className="text-lg font-semibold mb-2">Profile Details</h3>
          <div className="space-y-2 text-sm">
            <p>
              <strong>Name:</strong> {userName}
            </p>
            <p>
              <strong>Surname:</strong> {userLastName}
            </p>
            <p>
              <strong>Email:</strong> {userEmail}
            </p>
          </div>
        </div>
        {/* Texto para eliminar cuenta */}
        <div className="mt-6 flex justify-center">
          <button
            className="text-red-500 text-sm underline"
            onClick={onDeleteAccount}
          >
            Eliminar cuenta
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;

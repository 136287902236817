import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";

import { TimerContext } from "./timerContext_new";

// Imágenes
import backgroundImage from "../imgs/people_background.webp";
import rolloClosedTop from "../imgs/cuartos/BigBrother/rollo_top.webp";
import rolloClosedBottom from "../imgs/cuartos/BigBrother/rollo_bottom.webp";
import rolloComplete from "../imgs/cuartos/BigBrother/rollo_complete.webp";
import timeRedIcon from "../imgs/timer-red.svg";

// Firebase
import { useAuth } from "./AuthProvider";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const IntroBigBrother = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState("");
  const [isRolloOpen, setIsRolloOpen] = useState(false); // Controla si el pergamino está abierto o cerrado
  const navigate = useNavigate();
  const { timeLeft, startTimer } = useContext(TimerContext);

  // Firebase
  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePasswordSubmit = async (password) => {
    if (password === "27/04/2030") {
      try {
        // Actualizar currentPhase a 2 en Firestore
        const userDocRef = doc(db, "big_brother", currentUser.uid);
        await updateDoc(userDocRef, { currentPhase: 2 });

        // Agregar un pequeño retraso antes de la navegación
        setTimeout(() => {
          setShowPopup(false);
          setError("");
          navigate("/BlackBigBrother");
        }, 3000);
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  const toggleRollo = () => {
    setIsRolloOpen((prevState) => !prevState); // Cambia el estado del pergamino entre abierto y cerrado
  };

  useEffect(() => {
    // Solo iniciar el temporizador si no ha sido iniciado antes
    const timerStarted = localStorage.getItem("timerStartTime");
    if (!timerStarted) {
      startTimer(); // Inicia el temporizador solo si no está corriendo
    }
  }, [startTimer]);

  useEffect(() => {
    // Inicializa currentPhase en Firestore si no existe
    const setInitialPhase = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "big_brother", currentUser.uid);
        await setDoc(userDocRef, { currentPhase: 1 }, { merge: true });
      }
    };
    setInitialPhase();
  }, [currentUser]);

  return (
    <div
      className="min-h-screen bg-blueCTCbg bg-cover bg-top bg-no-repeat flex flex-col items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="sm:absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>
      {/* Título */}
      <div className="absolute top-8 text-center">
        <h1 className="text-white text-5xl font-bold bg-redCTC px-8 py-2">
          The Big Brother
        </h1>
      </div>
      {/* Pergamino cerrado */}
      {!isRolloOpen && (
        <div
          className="flex flex-col items-center justify-center cursor-pointer max-w-xl -mt-[25%]"
          onClick={toggleRollo}
        >
          <img src={rolloClosedTop} alt="Rollo cerrado" className="w-full" />
          <img
            src={rolloClosedBottom}
            alt="Rollo cerrado inferior"
            className="w-full -mt-2"
          />
        </div>
      )}

      {/* Pergamino abierto con animación */}
      {isRolloOpen && (
        <div
          className="flex flex-col items-center justify-center animate-roll-open max-w-xl mt-12"
          style={{
            animationDuration: "1s",
            animationTimingFunction: "ease-out",
          }}
        >
          <img
            src={rolloComplete}
            alt="Rollo abierto"
            className="mb-4 w-full"
          />
          <ContinueButton setShowPopup={setShowPopup} />
        </div>
      )}
      {/* Mostrar el PopUp de contraseña si está abierto */}
      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa la fecha"
          placeholderText="DD/MM/AAAA"
          buttonText="Verificar Fecha"
          error={error}
        />
      )}
    </div>
  );
};

export default IntroBigBrother;

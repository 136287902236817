// Loader.js
import React from "react";
import loader from "../imgs/video_loader.webm";

const Loader = () => {
  return (
    <div className="loader">
      <video
        className="loader-video"
        src={loader}
        autoPlay
        loop
        muted
        playsinline
      ></video>
    </div>
  );
};

export default Loader;

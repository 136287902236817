import React from 'react';

const puerta33 = () => {
  return (
    <div>
      Puerta 33
    </div>
  );
};

export default puerta33;
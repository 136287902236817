import React, { useState } from "react";
import {
  signInWithGoogle,
  registerWithEmailAndPassword,
  signInWithFacebook,
} from "../../firebase"; // Importar funciones de Firebase
import { Link, useNavigate } from "react-router-dom"; // Importar Link

// Crear y exportar la función FormSignUp
export default function FormSignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(""); // Nombre
  const [lastName, setLastName] = useState(""); // Apellido
  const [dayOfBirth, setDayOfBirth] = useState(""); // Día de nacimiento
  const [monthOfBirth, setMonthOfBirth] = useState(""); // Mes de nacimiento
  const [yearOfBirth, setYearOfBirth] = useState(""); // Año de nacimiento
  const [gender, setGender] = useState(""); // Género
  const [referralSource, setReferralSource] = useState(""); // Fuente de referencia

  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      // Combina la fecha de nacimiento
      await registerWithEmailAndPassword(
        email,
        password,
        firstName,
        lastName,
        dayOfBirth,
        monthOfBirth,
        yearOfBirth,
        gender,
        referralSource
      );
      alert("Usuario registrado con éxito");
      navigate("/");
    } catch (error) {
      console.error("Error al registrar el usuario:", error);
      if (error.code === "auth/email-already-in-use") {
        alert(
          "El correo electrónico ya está registrado. Por favor, inicia sesión."
        );
      } else {
        alert(
          "Hubo un error al registrar el usuario. Por favor, inténtalo de nuevo."
        );
      }
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      await signInWithGoogle(() => navigate("/"));
    } catch (error) {
      console.error("Error al registrarse con Google:", error);
      alert(
        "Hubo un error al registrarse con Google. Por favor, inténtalo de nuevo."
      );
    }
  };

  return (
    <div className="bg-blueCTC px-10 py-20 rounded-3xl border-2 border-gray-100 my-4">
      <h1 className="text-5xl font-semibold text-redCTC">
        ¡A resolver misterios!
      </h1>
      <p className="font-medium text-lg text-gray-500 mt-4">
        ¡Bienvenido! Por favor ingresa tus datos.
      </p>
      <div className="mt-8 space-y-2">
        {/* Nombre y Apellido */}
        <div>
          <label className="text-lg font-semibold text-redCTC">Nombre</label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
            placeholder="Ingresa tu nombre"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label className="text-lg font-semibold text-redCTC">Apellido</label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
            placeholder="Ingresa tu apellido"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        {/* Correo electrónico y Contraseña */}
        <div>
          <label className="text-lg font-semibold text-redCTC">
            Correo electrónico
          </label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
            placeholder="Ingresa tu correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label className="text-lg font-semibold text-redCTC">
            Contraseña
          </label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
            placeholder="Ingresa tu contraseña"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {/* Fecha de nacimiento */}
        <div>
          <label className="text-lg font-semibold text-redCTC">
            Fecha de nacimiento
          </label>
          <div className="flex gap-4">
            <input
              className="w-1/3 border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
              placeholder="DD"
              value={dayOfBirth}
              onChange={(e) => setDayOfBirth(e.target.value)}
              maxLength={2}
            />
            <input
              className="w-1/3 border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
              placeholder="MM"
              value={monthOfBirth}
              onChange={(e) => setMonthOfBirth(e.target.value)}
              maxLength={2}
            />
            <input
              className="w-1/3 border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
              placeholder="AAAA"
              value={yearOfBirth}
              onChange={(e) => setYearOfBirth(e.target.value)}
              maxLength={4}
            />
          </div>
        </div>

        {/* Género */}
        <div>
          <label className="text-lg font-semibold text-redCTC">
            Género (opcional)
          </label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
            placeholder="Ingresa tu género (opcional)"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          />
        </div>

        {/* ¿Cómo te enteraste de nosotros? */}
        <div>
          <label className="text-lg font-semibold text-redCTC">
            ¿Cómo te enteraste de nosotros? (opcional)
          </label>
          <input
            className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-blueCTC text-white"
            placeholder="Fuente de referencia"
            value={referralSource}
            onChange={(e) => setReferralSource(e.target.value)}
          />
        </div>

        {/* Botones */}
        <div className="mt-8 flex flex-col gap-y-4">
          <button
            onClick={handleRegister}
            className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 bg-redCTC text-white text-lg font-bold rounded-xl mt-2"
          >
            Registrarme
          </button>
          <button
            onClick={handleGoogleSignUp}
            className="flex border-2 border-gray-100 items-center justify-center gap-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out font-semibold rounded-xl py-3 bg-white"
          >
            {/* Icono de Google */}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.26644 9.76453C6.19903 6.93863 8.85469 4.90909 12.0002 4.90909C13.6912 4.90909 15.2184 5.50909 16.4184 6.49091L19.9093 3C17.7821 1.14545 15.0548 0 12.0002 0C7.27031 0 3.19799 2.6983 1.24023 6.65002L5.26644 9.76453Z"
                fill="#EA4335"
              />
              <path
                d="M16.0406 18.0142C14.9508 18.718 13.5659 19.0926 11.9998 19.0926C8.86633 19.0926 6.21896 17.0785 5.27682 14.2695L1.2373 17.3366C3.19263 21.2953 7.26484 24.0017 11.9998 24.0017C14.9327 24.0017 17.7352 22.959 19.834 21.0012L16.0406 18.0142Z"
                fill="#34A853"
              />
              <path
                d="M19.8342 20.9978C22.0292 18.9503 23.4545 15.9019 23.4545 11.9982C23.4545 11.2891 23.3455 10.5255 23.1818 9.81641H12V14.4528H18.4364C18.1188 16.0119 17.2663 17.2194 16.0407 18.0108L19.8342 20.9978Z"
                fill="#4A90E2"
              />
              <path
                d="M5.27698 14.2663C5.03833 13.5547 4.90909 12.7922 4.90909 11.9984C4.90909 11.2167 5.03444 10.4652 5.2662 9.76294L1.23999 6.64844C0.436587 8.25884 0 10.0738 0 11.9984C0 13.918 0.444781 15.7286 1.23746 17.3334L5.27698 14.2663Z"
                fill="#FBBC05"
              />
            </svg>
            Ingresar con Google
          </button>
        </div>

        <div className="mt-8 flex justify-center items-center">
          <p className="font-semibold text-base text-redCTC">
            ¿Ya estás registrado?
          </p>
          <Link
            to="/signin"
            className="text-base font-semibold ml-2 text-white"
          >
            Inicia Sesión
          </Link>
        </div>
      </div>
    </div>
  );
}

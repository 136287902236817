import React, { useState, useEffect } from "react";
import Header from "./header";

import Fondocalle from "../imgs/fondocalle.png";
import target from "../imgs/target.svg";
import rocket from "../imgs/rocket.svg";
import heartLock from "../imgs/heart-lock.svg";
import MISION from "../imgs/MISION.svg";
import VISION from "../imgs/VISION.svg";
import VALORES from "../imgs/VALORES.svg";

import Footer from "./footer";
import "../index.css";
import ClientsSlider from "./clientsSlider";
import MobileClientsSlider from "./mobileClientsSlider"; // Importamos el nuevo slider

const AboutUs = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 920); // Estado para detectar pantalla móvil

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 920);
    };

    // Escuchar el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el evento al desmontar
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative w-full h-full bg-blueCTCaboutus">
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <main
        className={`relative w-full py-12 transition-all duration-300 
        ${menuOpen ? "landscape-mobile" : "mt-0"}
        landscape-desktop`}
        style={{
          backgroundImage: `url(${Fondocalle})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl lg:text-5xl text-white font-nebulica font-bold mb-8 text-center bg-redCTC px-4 py-2 mt-12">
            SOBRE NOSOTROS
          </h1>
          <p className="w-full lg:w-1/2 text-sm md:text-lg text-white mb-8 font-roboto-mono text-center px-4">
            El{" "}
            <span className="font-semibold text-redCTC text-primary">
              escape room híbrido
            </span>{" "}
            combina lo mejor de dos mundos: la emoción de los juegos de escape
            físicos con la comodidad de la experiencia digital.
          </p>

          {/* Sección Misión */}
          <div className="relative flex flex-col md:flex-row items-center justify-between mt-4 md:mb-0 mb-20">
            <img
              src={MISION}
              className="md:absolute md:top-[-20px] md:left-[-30px] absolute top-[-20px] left[50%] w-32 md:w-48 mb-4 md:mb-0 mx-auto"
              alt="Misión"
            />
            <div className="custom-rectangle flex justify-between items-center p-4">
              <p className="font-roboto-mono text-white w-full md:w-3/4 text-center text-xs sm:text-sm md:text-base lg:text-xl">
                Proveer nuevas alternativas de entretenimiento en los
                aeropuertos más importantes del país.
              </p>
            </div>
            <img
              src={target}
              className="md:absolute md:right-[-50px] md:bottom-0 absolute bottom-[-90px] h-32 md:h-40 w-32 md:w-40 mt-4 md:mt-0"
              alt="Target"
            />
          </div>

          {/* Sección Visión */}
          <div className="relative flex flex-col md:flex-row items-center justify-between mt-8 md:mb-0 mb-20">
            <img
              src={VISION}
              className="md:absolute md:top-[-20px] md:right-[-30px] absolute top-[-20px] left[50%] w-32 md:w-48 mb-4 md:mb-0 mx-auto"
              alt="Visión"
            />
            <div className="custom-rectangle flex justify-between items-center p-4">
              <p className="font-roboto-mono text-white w-full md:w-3/4 text-center text-xs sm:text-sm md:text-base lg:text-xl">
                Ayudar a los turistas a escapar del aburrimiento a través de
                nuevas alternativas de entretenimiento.
              </p>
            </div>
            <img
              src={rocket}
              className="md:absolute md:left-[-50px] md:bottom-0 absolute bottom-[-90px] h-32 md:h-40 w-32 md:w-40 mt-4 md:mt-0"
              alt="Rocket"
            />
          </div>

          {/* Sección Valores */}
          <div className="relative flex flex-col md:flex-row items-center justify-between mt-8 md:mb-0 mb-20">
            <img
              src={VALORES}
              className="md:absolute md:top-[-20px] md:left-[-30px] absolute top-[-20px] left[50%] w-32 md:w-48 mb-4 md:mb-0 mx-auto"
              alt="Valores"
            />
            <div className="custom-rectangle flex justify-between items-center p-4">
              <p className="font-roboto-mono text-white w-full md:w-3/4 text-center text-xs sm:text-sm md:text-base lg:text-xl">
                <strong>Entretenimiento Creativo:</strong> Creamos experiencias
                innovadoras.
                <strong>Proactividad Curiosa:</strong> Exploramos con
                iniciativa.
              </p>
            </div>
            <img
              src={heartLock}
              className="md:absolute md:right-[-50px] md:bottom-0 absolute bottom-[-100px] h-32 md:h-40 w-32 md:w-40 mt-4 md:mt-0"
              alt="Heart Lock"
            />
          </div>

          {/* Sliders de Clientes */}
          <div className="flex flex-col mt-8 mb-4">
            <p className="text-redCTC font-semibold text-xl">Nos respaldan</p>
          </div>
          <div>
            {/* Mostrar slider según el tamaño de pantalla */}
            {isMobile ? <MobileClientsSlider /> : <ClientsSlider />}
          </div>
        </div>
      </main>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;

import React, { useState, useEffect } from "react";

const FlightInfoPopUp = ({ isOpen, onClose, highlightEscapeRoom }) => {
  const [city, setCity] = useState("");
  const [terminal, setTerminal] = useState("");
  const [availableTerminals, setAvailableTerminals] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setCity("");
      setTerminal("");
      setAvailableTerminals([]);
    }
  }, [isOpen]);

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);

    switch (selectedCity) {
      case "CDMX":
        setAvailableTerminals(["AICM 1", "AICM 2"]);
        break;
      case "Monterrey":
        setAvailableTerminals(["MTY A", "MTY B"]);
        break;
      case "Guadalajara":
        setAvailableTerminals(["GDL A", "GDL B", "GDL C"]);
        break;
      default:
        setAvailableTerminals([]);
    }
    setTerminal("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClose(); // Cierra el pop-up
    highlightEscapeRoom(city, terminal); // Pasa la ciudad y la terminal seleccionada para resaltar la tarjeta correspondiente

    // Desplaza a la sección de híbridos
    const hybridsSection = document.getElementById("hibridosinfo");
    if (hybridsSection) {
      hybridsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          Ubica tu Juego
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Ciudad:
            </label>
            <select
              value={city}
              onChange={handleCityChange}
              required
              className="block appearance-none w-full bg-white border border-gray-400 px-4 py-2 rounded shadow focus:outline-none"
            >
              <option value="">Selecciona una ciudad</option>
              <option value="CDMX">CDMX</option>
              <option value="Monterrey">Monterrey</option>
              <option value="Guadalajara">Guadalajara</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Terminal:
            </label>
            <select
              value={terminal}
              onChange={(e) => setTerminal(e.target.value)}
              required
              className="block appearance-none w-full bg-white border px-4 py-2 rounded shadow"
              disabled={!availableTerminals.length}
            >
              <option value="">Selecciona una terminal</option>
              {availableTerminals.map((term) => (
                <option key={term} value={term}>
                  {term}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-center space-x-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded-md"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="bg-redCTC text-white px-4 py-2 rounded-md"
            >
              Buscar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FlightInfoPopUp;

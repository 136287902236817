import React from "react";
import continueIcon from "../../imgs/continue_white.svg";

const ContinueButtonGold = ({ setShowPopup }) => {
  return (
    <div className="mt-6 flex items-center justify-center">
      <button
        className="px-6 py-3 rounded-md text-white flex items-center justify-center font-bold"
        style={{
          background:
            "linear-gradient(90deg, #916002 0%, #EFBE3F 49%, #9D6801 100%)",
        }}
        onClick={() => setShowPopup(true)}
      >
        <img
          src={continueIcon}
          alt="Icono de continuar"
          className="w-6 h-6 mr-2"
        />
        Continuar
      </button>
    </div>
  );
};

export default ContinueButtonGold;
